import {Injectable} from "@angular/core";
import {ServerSentEventService} from "../../sse/server-sent-event.service";
import {SseEventModel} from "../dto/sse-event.model";
import {SseType} from "../../sse/server-sent-event.model";
import {CoverageTransactionChangeHandleService} from "./coverage-transaction-change-handle.service";
import {CoverageTransactionChangeSse} from "../dto/coverage-transaction-change-sse";
import {DailyPricesService} from "./daily-prices.service";
import {DailyPricesStateChange} from "../dto/daily-prices-state.model";

@Injectable({
    providedIn: 'root'
})
export class SseService {
    constructor(private serverSentEventService: ServerSentEventService,
                private coverageTransactionChangeHandleService: CoverageTransactionChangeHandleService,
                private dailyPricesService: DailyPricesService) {
    }

    public initSseHandler(): void {
        this.serverSentEventService.subscribe<SseEventModel>(sseEvent => {
            if (sseEvent.sseType === SseType.COVERAGE_TRANSACTION_CHANGE) {
                this.coverageTransactionChangeHandleService.handleConsumptionPlanImportSse(sseEvent as CoverageTransactionChangeSse);
            } else if (sseEvent.sseType === SseType.DAILY_PRICE_STATE_CHANGE) {
                this.dailyPricesService.handleDailyPriceStateChange(sseEvent as DailyPricesStateChange);
            } else {
                throw new Error('Unknown sse type');
            }
        });
    }
}
