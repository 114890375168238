import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {ProductType} from '../../agreement-details/agreement-enums.model';
import {OfferPeriod} from '../../offer-claim/model/offer-period.enum';
import {UtilService} from '../../services/util.service';
import {
    AggregationTableType,
    OfferClaimPeriodType,
    OfferClaimQuantitiesModel,
    QuantitiesForPeriodModel,
    QuantityValueType
} from '../aggregation-table-data.model';

import {DialogService} from '../../dialog/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import * as _ from "lodash";

import {OfferClaimDto} from '../../offer-claim/model/OfferClaimDto';

import {Subject} from 'rxjs';
import {DeliveryPeriod} from '../../dto/delivery.periods';
import {DeliveryPeriodDto} from '../../dto/delivery-period-dto';
import {takeUntil} from 'rxjs/operators';
import {DeliveryPeriodForOffer} from '../../offer-claim/model/DeliveryPeriodForOffer';
import {DailyRatesDto} from '../../daily-rates/model/DailyRatesDto';
import * as moment from 'moment';
import {OfferClaimService} from "../../../coverage-transactions/offer-claim.service";
import {
  CoverageTransactionsPeriodTabService
} from "../../../coverage-transactions/coverage-transactions-period-tab.service";
import {
  NewClaimDialogConfig,
  NewClaimDialogConfigBuilder
} from "../../../coverage-transactions/offer-claim/new-claim-dialog/new-claim-dialog.model";

@Component({
    selector: 'aggregation-table-content',
    templateUrl: 'aggregation-table-content.component.html',
    styleUrls: ['aggregation-table-content.component.scss']
})
export class AggregationTableContentComponent implements OnInit, OnDestroy {

    public readonly AggregationTableType: typeof AggregationTableType = AggregationTableType;
    public readonly ProductType: typeof ProductType = ProductType;
    public readonly OfferPeriod: typeof OfferPeriod = OfferPeriod;

    @Input() productType: ProductType;
    @Input() quantities: OfferClaimQuantitiesModel;
    @Input() otherQuantities: OfferClaimQuantitiesModel;
    @Input() tableType: AggregationTableType;

    private selectedDeliveryPeriod: DeliveryPeriodDto;
    private destroy: Subject<void> = new Subject<void>();

    constructor(private offerClaimService: OfferClaimService,
                private coverageTransactionsPeriodTabService: CoverageTransactionsPeriodTabService,
                private dialogService: DialogService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.initSubscriptions();
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    public get bgColorCssClass(): string {
        return this.tableType === AggregationTableType.BEFORE_TRADE ? 'bg-cezorange' : 'bg-cezgreen';
    }

    public getClickableStateCssClass(product: QuantitiesForPeriodModel, clickableClass: string): string {
        if (!product.available) {
            // if the product is not available, the table field is not clickable, but gray
            return ' unavailable';
        }

        return product.illiquid ? clickableClass + ' illiquid' : clickableClass;
    }

    public getDataContainerClass(quantityModel: QuantitiesForPeriodModel): string {
        // OfferPeriod.M
        return this.isMarchMayOrSeptember(quantityModel.periodNumber) ? 'data-gap' : 'data';
    }

    public getCssClassForTextContainer(quantityModel: QuantitiesForPeriodModel): string {
        if (quantityModel.period === OfferPeriod.Q) {
            return quantityModel.periodNumber < 4 ? 'gap-r' : 'no-gap-r';
        }
        // OfferPeriod.M
        if (quantityModel.periodNumber === 12) {
            return 'no-gap-r';
        }

        return this.isMarchMayOrSeptember(quantityModel.periodNumber) ? 'gap-r' : 'no-gap-r border-right-0';
    }

    /**
     * @return string In before trade table, if the percentage is less than 0, returns 'over-hedged' class.
     * In after trade table if the data is modified compared to before trade data, returns 'changed' class.
     * Otherwise, returns an empty string.
     * */
    public getDataCssClass(model: QuantitiesForPeriodModel, valueType: QuantityValueType): string {
        if (valueType === 'percent' && model.percent < 0) {
            // if the position is over-hedged (the percent is < 0), the text is highlighted
            return 'over-hedged';
        }
        if (UtilService.hasUnsetElement(this.otherQuantities, model, valueType) ||
            _.isNil(model[valueType]) ||
            _.isNil(this.resolvePeriod(model.period)) ||
            _.isNil(this.otherQuantities[this.resolvePeriod(model.period)])) {
            return '';
        }
        let other: QuantitiesForPeriodModel;
        if (model.period === OfferPeriod.Y) {
            other = this.otherQuantities[this.resolvePeriod(model.period)] as QuantitiesForPeriodModel;
        } else {
            let otherArray: QuantitiesForPeriodModel[] = (this.otherQuantities[this.resolvePeriod(model.period)] as QuantitiesForPeriodModel[])
                .filter((o: QuantitiesForPeriodModel): boolean => o.periodNumber === model.periodNumber);
            other = !!otherArray && otherArray.length === 1 ? otherArray[0] : null;
        }
        if (UtilService.hasUnsetElement(other)) {
            return '';
        }

        return model[valueType] !== other[valueType] ? 'changed' : '';
    }

    public showTable(): boolean {
        return !!this.quantities &&
            !!this.quantities.yearlyQuantities ||
            (!!this.quantities.quarterlyQuantities && this.quantities.quarterlyQuantities.length > 0) ||
            (!!this.quantities.monthlyQuantities && this.quantities.monthlyQuantities.length > 0);
    }

    public purchaseNewClaim(product: QuantitiesForPeriodModel): void {
        if (!product.available) {
            return;
        }

        const selectedDeliveryPeriodForOffer: DeliveryPeriodForOffer = this.coverageTransactionsPeriodTabService.deliveryPeriodForOffers
            .filter((dp: DeliveryPeriodForOffer) => dp.deliveryPeriod.id === this.selectedDeliveryPeriod.id)[0];
        const config: NewClaimDialogConfig = NewClaimDialogConfigBuilder.builder()
            .withClaim(this.createOfferClaim(product))
            .withSelectedDeliveryPeriod(selectedDeliveryPeriodForOffer)
            // Create daily rate for newClaimDialog from the given product and delivery period.
            // It is necessary for the fields to be filled automatically.
            .withDailyRate(this.getDailyRate(product, this.selectedDeliveryPeriod))
            .build();

        // If the product is illiquid, confirm dialog is needed for the purchase.
        // Product is illiquid if the period number is at a greater distance than 2 from current date. Calculated in back end side.
        if (product.illiquid) {
            this.dialogService.confirm(
                this.translateService.instant("offerClaim.confirmDialog.title"),
                this.translateService.instant("offerClaim.confirmDialog.purchaseIlliquidProduct")
            ).subscribe((value: boolean): void => {
                if (value) {
                    this.offerClaimService.addNewOrEditOfferClaim(config);
                } else {
                    product.cancelled = true;
                }
            });
        } else {
            this.offerClaimService.addNewOrEditOfferClaim(config);
        }
    }

    private resolvePeriod(period: OfferPeriod): OfferClaimPeriodType {
        switch (period) {
            case OfferPeriod.Y:
                return OfferClaimPeriodType.yearlyQuantities;
            case OfferPeriod.Q:
                return OfferClaimPeriodType.quarterlyQuantities;
            case OfferPeriod.M:
                return OfferClaimPeriodType.monthlyQuantities;
            default:
                return null;
        }
    }

    private isMarchMayOrSeptember(periodNumber: number): boolean {
        return [3, 6, 9].indexOf(periodNumber) !== -1;
    }

    /**
     * Create offerClaim that contains the delivery period with its id for the newClaimDialog.
     * */
    private createOfferClaim(product: QuantitiesForPeriodModel): OfferClaimDto {
        const offerClaim: OfferClaimDto = new OfferClaimDto();

        offerClaim.period = product.period;
        offerClaim.periodNumber = product.periodNumber;
        offerClaim.averagingTransaction = false;
        offerClaim.deliveryPeriod = ({id: this.selectedDeliveryPeriod.id} as DeliveryPeriod);

        return offerClaim;
    }

    /**
     * Retrieves the daily rate for a given product and delivery period.*
     * @param {QuantitiesForPeriodModel} product - The product for which to retrieve the daily rate.
     * @param {DeliveryPeriodDto} deliveryPeriod - The delivery period for which to retrieve the daily rate.
     * @return {DailyRatesDto} The daily rate for the specified product and delivery period.
     */
    private getDailyRate(product: QuantitiesForPeriodModel, deliveryPeriod: DeliveryPeriodDto): DailyRatesDto {
        const year: string = moment(deliveryPeriod.startTime).year().toString();
        const dailyRate: DailyRatesDto = new DailyRatesDto();

        dailyRate.withoutPrice = true;
        if (product.period === OfferPeriod.Y) {
            dailyRate.name = year;
        } else {
            dailyRate.name = `${year}${product.period}${product.periodNumber}`;
        }

        return dailyRate;
    }

    private initSubscriptions(): void {
        this.coverageTransactionsPeriodTabService.getSelectedDeliveryPeriod().pipe(takeUntil(this.destroy))
            .subscribe((dp: DeliveryPeriodDto) => this.selectedDeliveryPeriod = dp);
    }
}
