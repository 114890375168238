import * as moment from 'moment';
import {Moment} from 'moment';

export class DateUtil {

    public static readonly MIN_DATE: Date = new Date('0001-01-01');
    public static readonly MAX_DATE: Date = new Date('9999-12-31');

    public static readonly ISO_8601_PATTERN = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/;

    private static readonly BUDAPEST_TZ: string = 'Europe/Budapest';

    /**
     * startDate is inclusive, endDate is exclusive
     * @param startDate Date
     * @param endDate Date
     * @returns Number of total days (without decimals) between two dates
     */
    public static calculateDaysBetween(startDate: Date | Moment, endDate: Date | Moment): number {
        return moment(endDate).diff(moment(startDate), 'days');
    }

    public static tomorrow(): Date {
        return moment().startOf('day').add(1, 'days').toDate();
    }

    public static add(date: Date, amount: moment.DurationInputArg1, unitOfTime: moment.DurationInputArg2): Date {
        return moment(date).add(amount, unitOfTime).toDate();
    }

    public static subtract(date: Date, amount: moment.DurationInputArg1, unitOfTime: moment.DurationInputArg2): Date {
        return moment(date).subtract(amount, unitOfTime).toDate();
    }

    public static isNowBeforeDate(date: Date): boolean {
        return moment().isBefore(moment(date));
    }

    static isNowInTheInterval(startTime: Date, endTime: Date): boolean {
        return !moment().isBefore(moment(startTime)) && !moment().isAfter(moment(endTime))
    }

    public static nowInBudapestTz(): Moment {
        return moment.tz(this.BUDAPEST_TZ);
    }

    public static getMomentInBudapestTz(date: Date): Moment {
        return moment(date).tz(this.BUDAPEST_TZ);
    }
}
