import {Directive, ElementRef, OnInit} from "@angular/core";
import {NgControl} from "@angular/forms";
import {DecimalPipe} from "@angular/common";
import {NumberStringUtil} from "../../core/util/number-string.util";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Directive({
    selector: '[thousands-separator]'
})
export class ThousandsSeparatorDirective implements OnInit {
    private el: HTMLInputElement;
    private destroy: Subject<void> = new Subject();

    constructor(
        private elementRef: ElementRef,
        private decimalPipe: DecimalPipe,
        private control: NgControl
    ) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit(): void {
        const abstractControl = this.control.control;
        if (abstractControl) {
            abstractControl.valueChanges.pipe(takeUntil(this.destroy), debounceTime(1000)).subscribe((value: string) => {
                this.el.value = this.decimalPipe.transform(NumberStringUtil.convertToNumber(value));
            });
        }
    }


}
