<div mat-dialog-title class="modal-header">
    <button mat-dialog-close aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
    <h2>{{data.title}}</h2>
</div>
<div mat-dialog-content class="modal-body bottom-border">
    <mat-form-field class="w-100">
        <input matInput #answer placeholder="{{data.message}}" required>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="null" class="cez-color--dark">{{'common.action.cancel' | translate}}</button>
    <button mat-raised-button
            color="primary"
            [mat-dialog-close]="answer.value"
            [disabled]="!answer.value">
        {{'common.action.ok' | translate}}
    </button>
</div>
