import {CoverageTransactionChangeSse} from "../dto/coverage-transaction-change-sse";
import {SseType} from "../../sse/server-sent-event.model";
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CoverageTransactionChangeHandleService {
    public coverageTransactionChangeSse: BehaviorSubject<CoverageTransactionChangeSse> =
        new BehaviorSubject<CoverageTransactionChangeSse>({sseType: SseType.COVERAGE_TRANSACTION_CHANGE, agreementId: null, deliveryPeriodId: null});

    public handleConsumptionPlanImportSse(response: CoverageTransactionChangeSse) {
        this.coverageTransactionChangeSse.next({
            sseType: response.sseType,
            agreementId: response.agreementId,
            deliveryPeriodId: response.deliveryPeriodId
        });
    }
}
