<div class="modal-header">
  <h4 class="modal-title">
    {{(fs.isModificationMode() ? 'offerClaim.modifyTitle' : 'offerClaim.addTitle') | translate}}
  </h4>
  <button (click)="closeDialog(false)" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="fs.form" (ngSubmit)="save()">
  <div class="modal-body">
    <div class="row justify-content-around mx-2 mb-4">
      <div class="col-6 text-center p-2 delivery-period-selector"
           [class.active]="!fs.averagingControl.value"
           [class.disabled]="fs.averagingControl.disabled && fs.averagingControl.value"
           (click)="fs.setAveraging(false)">{{'offerClaim.transactionType.singleTransaction' | translate}}</div>
      <div class="col-6 text-center p-2 delivery-period-selector"
           [class.active]="fs.averagingControl.value"
           [class.disabled]="fs.averagingControl.disabled && !fs.averagingControl.value"
           (click)="fs.setAveraging(true)">{{'offerClaim.transactionType.averagingTransaction' | translate}}</div>
    </div>

    <div class="row form-group"
         [class.d-none]="fs.partnerAndDeliveryPeriodHidden()">
      <div class="col-6"
           [class.d-none]="fs.partnerHidden()">
        <label for="partner">{{'offerClaim.tableField.partners' | translate}}</label>
        <select class="form-control"
                [formControl]="fs.partnerControl"
                id="partner">
          <option hidden [value]="null"></option>
          <option *ngFor="let partner of fs.partners"
                  [ngValue]="partner">
            {{partner.name}}
          </option>
        </select>
      </div>

      <div [class.col-6]="!fs.partnerHidden()"
           [class.col-12]="fs.partnerHidden()"
           [class.d-none]="fs.deliveryPeriodHidden()">
        <label for="deliveryPeriod">{{'offerClaim.tableField.deliveryPeriod' | translate}}</label>
        <select class="form-control"
                [formControl]="fs.deliveryPeriodControl"
                id="deliveryPeriod">
          <option hidden [value]="null"></option>
          <option *ngFor="let deliveryPeriod of fs.deliveryPeriods"
                  [disabled]="deliveryPeriodDisabled(deliveryPeriod)"
                  [ngValue]="deliveryPeriod">
            {{deliveryPeriod.deliveryPeriod.startTime | cezDate}}
            - {{deliveryPeriod.deliveryPeriod.endTime | cezDate}}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-group"
         [class.d-none]="!fs.isDeliveryPeriodDetermined()">
      <div class="col-6"
           [class.d-none]="fs.typeHidden()">
        <label for="type">{{'offerClaim.dialogField.type' | translate}}</label>
        <select class="form-control"
                [formControl]="fs.typeControl"
                id="type">
          <option *ngFor="let type of fs.types | keys"
                  [ngValue]="type.value">
            {{type.value}}
          </option>
        </select>
      </div>

      <div class="col-6"
           [class.d-none]="fs.marketHidden()">
        <label for="market">{{'offerClaim.dialogField.market' | translate}}</label>
        <select class="form-control"
                [formControl]="fs.marketControl"
                id="market">
          <option hidden [value]="null"></option>
          <option *ngFor="let market of fs.marketsFiltered" [ngValue]="market">{{market}}</option>
        </select>
      </div>
    </div>

    <div class="row form-group"
         [class.d-none]="!fs.isDeliveryPeriodDetermined()">
      <div class="col-6"
           [class.d-none]="fs.periodHidden()">
        <label for="period">{{'offerClaim.dialogField.period' | translate}}</label>
        <select class="form-control"
                [formControl]="fs.periodControl"
                id="period">
          <option hidden [value]="null"></option>
          <option *ngFor="let period of fs.periodsFiltered"
                  [ngValue]="period">{{('offerClaim.periodType.' + period) | translate}}</option>
        </select>
      </div>

      <div class="col-6"
           [class.d-none]="fs.periodNumberHidden()">
        <label for="periodNumber">{{'offerClaim.dialogField.periodNumber' | translate}}</label>
        <select class="form-control"
                [formControl]="fs.periodNumberControl"
                id="periodNumber">
          <option hidden [value]="null"></option>
          <option *ngFor="let periodNumber of fs.periodNumbers" [ngValue]="periodNumber">
            {{periodNumber}}
          </option>
        </select>
      </div>

      <div class="col-3"
           [class.d-none]="fs.startDateHidden()">
        <div>
          <label for="startDate">{{'offerClaim.tableField.startDate' | translate}}</label>
          <jhi-date-picker id="startDate"
                           [formControl]="fs.startDateControl"
                           [minDate]="fs.startMinDate"
                           [maxDate]="fs.startMaxDate"
                           [required]="fs.isCustomPeriod()"></jhi-date-picker>
        </div>
      </div>
      <div class="col-3"
           [class.d-none]="fs.endDateHidden()">
        <div>
          <label for="endDate">{{'offerClaim.tableField.endDate' | translate}}</label>
          <jhi-date-picker id="endDate"
                           [formControl]="fs.endDateControl"
                           [minDate]="fs.endMinDate"
                           [maxDate]="fs.endMaxDate"
                           [required]="fs.isCustomPeriod()"></jhi-date-picker>
        </div>
      </div>
    </div>

    <div class="row form-group"
         [class.mb-0]="fs.isPartnerMode() && !fs.isAveraging()"
         [class.d-none]="!fs.isDeliveryPeriodDetermined()">
      <div class="col-3"
           [class.d-none]="fs.purchaseStatusHidden()">
        <label for="purchaseStatus">{{'offerClaim.dialogField.purchaseDirection' | translate}}</label>
        <select class="form-control"
                [formControl]="fs.purchaseStatusControl"
                id="purchaseStatus">
          <option *ngFor="let status of (PurchaseStatus | keys)"
                  [ngValue]="status.key">
            <ng-container *ngIf="fs.isAveraging()">
              {{'offerClaimHistory.averagingPurchaseStatus.' + status.key | translate}}
            </ng-container>
            <ng-container *ngIf="!fs.isAveraging()">
              {{'offerClaimHistory.purchaseStatus.' + status.key | translate}}
            </ng-container>
          </option>
        </select>
      </div>

      <div class="col-3"
           [class.d-none]="fs.quantityHidden()">
        <label for="quantity">{{'offerClaim.dialogField.quantity' | translate}}</label>
        <input class="text-right form-control"
               type="text"
               [formControl]="fs.quantityControl"
               id="quantity"
               [min]="1"
               mask="separator.10"
               autocomplete="off"
               thousandSeparator="{{'number.thousandSeparator' | translate}}"
               (keydown)="onKeyDown($event)"
               [decimalMarker]="'number.decimalMarker' | translate"
               [dropSpecialCharacters]="true"
               [validation]="true">
      </div>

      <div class="col-3"
           [class.d-none]="fs.trancheHidden()">
        <label for="tranche">{{'offerClaim.dialogField.tranche' | translate}}</label>
        <input class="form-control"
               type="number"
               [formControl]="fs.trancheControl"
               id="tranche"/>
      </div>

      <div [class.col-6]="fs.isCashOut()"
           [class.col-3]="fs.isStepByStep()"
           [class.d-none]="fs.quantityMwhHidden()">
        <label for="quantityMwh">{{'offerClaim.dialogField.quantityMwh' | translate}}</label>
        <input class="form-control"
               type="text"
               [formControl]="fs.quantityMwhControl"
               id="quantityMwh"
               mask="separator.10"
               thousandSeparator="{{'number.thousandSeparator' | translate}}"
               [decimalMarker]="'number.decimalMarker' | translate">
      </div>

      <div class="col-3"
           [class.d-none]="fs.percentageHidden()">
        <label for="percentage">%</label>
        <input class="form-control"
               type="number"
               [formControl]="fs.percentControl"
               id="percentage"/>
      </div>
    </div>

    <div class="row form-group"
         [class.mb-0]="fs.priceHidden()"
         [class.d-none]="!fs.isDeliveryPeriodDetermined()">
      <div class="col-6"
           [class.d-none]="fs.averagingStartDateHidden()">
        <label for="averagingStartDate">{{'offerClaim.tableField.averagingStart' | translate}}</label>
        <jhi-date-picker [formControl]="fs.averagingStartControl"
                         [minDate]="fs.averagingStartMinDate"
                         [maxDate]="fs.averagingStartMaxDate"
                         [isDayDisabled]="isDayDisabled"
                         [required]="fs.isAveraging()"
                         readonly="true"
                         id="averagingStartDate"></jhi-date-picker>
      </div>
      <div class="col-6"
           [class.d-none]="fs.averagingEndDateHidden()">
        <label for="averagingEndDate">{{'offerClaim.tableField.averagingEnd' | translate}}</label>
        <jhi-date-picker [formControl]="fs.averagingEndControl"
                         [minDate]="fs.averagingEndMinDate"
                         [maxDate]="fs.averagingEndMaxDate"
                         [isDayDisabled]="isDayDisabled"
                         [required]="fs.isAveraging()"
                         readonly="true"
                         id="averagingEndDate"></jhi-date-picker>
      </div>
    </div>

    <div class="row form-group"
         [class.d-none]="!fs.isDeliveryPeriodDetermined() || (!fs.isDailyPriceModeWithPrice() && fs.isPartnerMode())">
      <div class="col-6"
           [class.d-none]="fs.priceHidden()">
        <label for="price">{{'offerClaim.dialogField.price' | translate}}</label>
        <input class="text-right form-control"
               type="text"
               [formControl]="fs.netPriceControl"
               id="price"
               mask="separator.2"
               autocomplete="off"
               maxlength="7"
               thousandSeparator="{{'number.thousandSeparator' | translate}}"
               (keydown)="onKeyDown($event)"
               [decimalMarker]="'number.decimalMarker' | translate"
               [dropSpecialCharacters]="true"
               [validation]="true">
      </div>
      <div class="col-6 curve-enabled"
           [class.d-none]="fs.curveEnabledHidden()">
        <p-checkbox class="d-inline mr-2"
                    [formControl]="fs.curveEnabledControl"
                    id="curveEnabled"
                    binary="true"></p-checkbox>
        <label class="d-inline" for="curveEnabled">{{'offerClaim.tableField.curveEnabled' | translate}}</label>
      </div>
    </div>
    <div *ngIf="hasWarning() || hasError()"
         class="row mx-0 mt-3">
      <div *ngIf="hasWarning()"
           class="alert alert-warning col-12 mb-2" role="alert">
        <ul class="m-0">
          <li *ngIf="otcMarketClosed">{{'validation.MARKET_IS_CLOSED' | translate}}</li>
          <li *ngIf="offerQuantityNonStandardStep()">{{'validation.OFFER_QUANTITY_NON_STANDARD_STEP_WARNING' | translate}}</li>
          <li *ngIf="hasIlliquidPeriod">{{'offerClaim.calculationForPeriod.ILLIQUID_PERIOD_WARNING' | translate}}</li>
          <li *ngFor="let warning of warningMessages">{{warning}}</li>
          <ng-container *ngIf="this.fs.isAdminMode()">
            <li *ngFor="let error of errorMessages">{{error}}</li>
            <li *ngIf="missingClicks()">{{'validation.AGREEMENT_MISSING_PARAM_CLICKS' | translate}}</li>
          </ng-container>
        </ul>
      </div>
      <div *ngIf="hasError()"
           class="alert alert-danger col-12 m-0" role="alert">
        <ul class="m-0">
          <li *ngFor="let error of errorMessages">{{error}}</li>
          <li *ngIf="missingClicks()">{{'validation.AGREEMENT_MISSING_PARAM_CLICKS' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="modal-footer d-block">
    <div class="row"
         [class.d-none]="fs.curveImportHidden()">
      <cez-file-upload-queue #fileUploadQueue [isMultipleFiles]="false">
        <cez-file-upload [file]="file" [id]="i"
                         [httpUrl]="'api/timeseries/import/preview'"
                         (onUpload)="onUpload($event)"
                         [autoUpload]="true"
                         [httpRequestParams]="agreementParams"
                         *ngFor="let file of fileUploadQueue.files; let i = index"></cez-file-upload>
      </cez-file-upload-queue>
      <input #fileInput [hidden]="true" multiple type="file" [fileUploadInputFor]="fileUploadQueue"
             id="curve"
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
    </div>
    <div class="row form-group m-0">
      <div class="col-10 pl-0"
           [class.d-none]="fs.curveImportHidden()">
        <button mat-raised-button
                class="btn btn-primary mr-2 text-white"
                type="button"
                [disabled]="fs.getTimeSeriesButtonsDisabled()"
                (click)="fileInput.click(); setTimeSeriesUploadParams();">
          <i class="material-icons">upload_file</i>{{'offerClaim.button.import' | translate}}
        </button>

        <button mat-raised-button
                class="btn btn-primary text-white"
                type="button"
                [disabled]="fs.getTimeSeriesButtonsDisabled()"
                (click)="exportTemplate()">
          <i class="material-icons">file_download</i>{{'offerClaim.button.template' | translate}}</button>
      </div>

      <div class="col-2 text-right pr-0"
           [class.col-2]="!fs.curveImportHidden()"
           [class.col-12]="fs.curveImportHidden()">
          <button type="button" class="btn cez-color--dark" (click)="this.activeModal.close()" jhiTranslate="common.action.cancel">
              Cancel
          </button>
          <button class="btn btn-primary ml-2"
                [disabled]="saveDisabled()"
                type="submit">{{'entity.action.save' | translate}}</button>
      </div>
    </div>

    <div *ngIf="fs.isPartnerMode() || fs.isAveraging()"
         class="row mt-2">
      <h5 *ngIf="fs.isPartnerMode()" class="m-0">{{(isLastMonthOfDeliveryPeriod() ? 'offerClaim.infoTextLastDp' : 'offerClaim.infoText') | translate}}</h5>
      <h5 *ngIf="fs.isAveraging()" class="m-0">{{'offerClaim.transactionType.infoText' | translate}}</h5>
    </div>
  </div>
</form>
