<div mat-dialog-title class="modal-header">
    <button mat-dialog-close aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
    <h2>{{data.title | translate}}</h2>
</div>
<div mat-dialog-content class="modal-body bottom-border">
    <div class="row">
        <label class="col-6" for="modifyParameter">
            {{data.paramName | translate}}
        </label>
        <div class="col-6">
            <input [type]="data.type"
                   class="form-control"
                   id="modifyParameter"
                   [(ngModel)]="value">
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button
            cez-color="dark"
            [mat-dialog-close]="null">
        {{'common.action.cancel' | translate}}
    </button>
    <button mat-raised-button
            color="primary"
            [disabled]="value === defaultValue"
            (click)="save()">
        {{'common.action.save' | translate}}
    </button>
</div>
