import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {DailyPricesService} from '../services/daily-prices.service';
import {CoverageTransactionsAdminService} from '../../coverage-transaction.admin/coverage-transactions.admin.service';
import {DailyRatesDto} from '../daily-rates/model/DailyRatesDto';
import {AgreementModel} from '../dto/agreement.model';
import {CoverageTransactionsService} from '../../coverage-transactions/coverage-transactions.service';
import {StateStorageService} from '../auth';
import {DeliveryPeriodForOffer} from '../offer-claim/model/DeliveryPeriodForOffer';
import * as moment from 'moment';
import * as _ from 'lodash';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CoverageTransactionsUtil} from '../../coverage-transactions/coverage-transactions.util';
import {DecimalPipe} from '@angular/common';
import {DailyRatesWithDifferenceDto} from './daily-rates.types';
import {DailyRateType} from '../daily-rates/model/DailyRatesType.enum';
import {DailyPriceStateChange} from '../daily-rates/model/DailyPriceStateChange';
import {DateUtil} from "../../core/util/date.util";

@Component({
  selector: 'daily-prices-dialog',
  templateUrl: 'daily-prices-dialog.component.html',
  styleUrls: ['daily-prices-dialog.component.scss']
})
export class DailyPricesDialogComponent implements OnInit, OnDestroy {

  public readonly EMPTY_PRICE: string = 'N/A';

  @Input() isHedgeUrl: boolean;
  @Output() modalClose: EventEmitter<any> = new EventEmitter();

  public DailyRateType: typeof DailyRateType = DailyRateType;

  public dailyRates: DailyRatesWithDifferenceDto[];
  public isActiveDailyPrices: boolean = false;
  public isPriceMonitoringButtonDisable: boolean = false;
  public isBuyProductButtonDisable: boolean = false;

  private destroy: Subject<any> = new Subject();

  constructor(public activeModal: NgbActiveModal,
              private dailyPricesService: DailyPricesService,
              private router: Router,
              private coverageTransactionsAdminService: CoverageTransactionsAdminService,
              private coverageTransactionsService: CoverageTransactionsService,
              private stateStorageService: StateStorageService,
              private numberPipe: DecimalPipe) {
  }

  ngOnInit(): void {
    this.loadDailyRates();
    this.setDailyPricesState();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public loadDailyRates(): void {
    this.coverageTransactionsAdminService.getDailyRates().pipe(takeUntil(this.destroy)).subscribe((data: DailyRatesDto[]) => {
      this.dailyRates = data
        .filter((rate: DailyRatesWithDifferenceDto) => !rate.withoutPrice)
        .map((rate: DailyRatesWithDifferenceDto) => {
          rate.previousPriceDiff = this.calculatePriceDifference(rate.currentPrice, rate.previousPrice);
          rate.previousPriceDMinus7Diff = this.calculatePriceDifference(rate.previousPrice, rate.previousPriceDMinus7);
          rate.previousPriceDMinus28Diff = this.calculatePriceDifference(rate.previousPriceDMinus7, rate.previousPriceDMinus28);

          return rate;
        });
      this.getAgreements();
    });
  }

  public buyProduct(rate: DailyRatesDto): void {
    if (this.isBuyProductButtonDisable) {
      return;
    }

    this.isBuyProductButtonDisable = true;

    this.dailyPricesService.navigateTo = 'cartTab';
    this.dailyPricesService.setSelectedRateForCart(rate);
    this.router.navigate(['/hedge-transaction/cart']);
    this.activeModal.close();

    this.isBuyProductButtonDisable = false;
  }

  public formatPrice(price: number | string): string {
    if (!this.isNil(price)) {
      return this.numberPipe.transform(price, '1.2-2');
    }

    return this.EMPTY_PRICE;
  }

  public isBuyProductButtonVisible(dailyRates: DailyRatesWithDifferenceDto): boolean {
    return (dailyRates.type != 'WEEKLY' && dailyRates.hasPeriodForRate) &&
      this.isActiveDailyPrices;
  }

  private getAgreements(): void {
    if (!this.stateStorageService.getSelectedCompanyId()) {
      this.router.navigate(['company/selector']);
      this.activeModal.close();
      return;
    }

    this.coverageTransactionsService.getAgreementsByPartner(this.stateStorageService.getSelectedCompanyId()).subscribe((agreements: AgreementModel[]) => {
      const deliveryPeriods: DeliveryPeriodForOffer[] = CoverageTransactionsUtil.convertAgreementsToDeliveryPeriodsForOffer(agreements)
        .filter((deliveryPeriod: DeliveryPeriodForOffer) => moment(deliveryPeriod.deliveryPeriod.endTime).isAfter(moment()));

      let periodYears: number [] = [];
      let ignoreProducts: string[] = [];

      deliveryPeriods.forEach((deliveryPeriod: DeliveryPeriodForOffer) => {
        const year: number = DateUtil.getMomentInBudapestTz(deliveryPeriod.deliveryPeriod.startTime).year();

        if (!deliveryPeriod.agreement.monthlyProducts) {
          ignoreProducts.push(year + 'M');
        }
        if (!deliveryPeriod.agreement.yearlyProducts) {
          ignoreProducts.push(year.toString());
        }
        if (!deliveryPeriod.agreement.quarterlyProducts) {
          ignoreProducts.push(year + 'Q');
        }

        periodYears.push(year);
      });

      this.dailyRates.forEach((rate: DailyRatesDto) => {
        const year: number = Number(rate.name.substring(0, 4));
        rate.hasPeriodForRate = _.includes(periodYears, year) && !_.includes(ignoreProducts, rate.name.substring(0, 5));
      });
    });
  }

  private setDailyPricesState(): void {
    this.dailyPricesService.dailyPricesStateChange.pipe(takeUntil(this.destroy)).subscribe((change: DailyPriceStateChange): void => {
      this.isActiveDailyPrices = change.type != null && change.type === DailyRateType.FIRM;
    });
  }

  private calculatePriceDifference(currentPrice: number | string, previousPrice: number | string) {
    return !this.isNil(currentPrice) && !this.isNil(previousPrice)
      ? (currentPrice as number) - (previousPrice as number)
      : null;
  }

  private isNil(price: number | string): price is undefined {
    return price === null || price === undefined;
  }

  getBackgroundClass(price: number): string {
    // no need to nilCheck as all falsy values need the default background
    if (!price) {
      return '';
    }

    if (price > 0) {
      return 'bg-red';
    }

    if (price < 0) {
      return 'bg-green';
    }
  }

  public goToPriceMonitoring(product: DailyRatesDto): void {
    if (this.isPriceMonitoringButtonDisable) {
      return;
    }

    this.isPriceMonitoringButtonDisable = true;

    this.dailyPricesService.navigateTo = 'priceMonitoringTab';
    this.dailyPricesService.navigateBack = 'overviewTab';
    this.dailyPricesService.setSelectedRateForPriceTracker(product);
    this.router.navigate(['/hedge-transaction/price-monitoring']);
    this.activeModal.close();

    this.isPriceMonitoringButtonDisable = false;
  }

  public isPriceMonitoringButtonVisible(): boolean {
    let permissions: string[] = this.stateStorageService.getEffectivePermissions();
    return permissions.indexOf('HEDGE_TRANSACTIONS_WRITE') !== -1;
  }

}
