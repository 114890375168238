import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    DailyRateData,
    NewClaimDialogConfig,
    NewClaimDialogMode,
    PartnerWithDeliveryPeriods
} from './new-claim-dialog.model';
import {combineLatest, forkJoin, Observable, Subject} from 'rxjs';
import {PurchaseStatus} from '../../../shared/offer-claim/model/purchase-status.enum';
import {TranslateService} from '@ngx-translate/core';
import {ExcelImportResult} from '../../../shared/time-series/model/excel-import-result.model';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {NewClaimDialogFormStateService} from './new-claim-dialog-form-state.service';
import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {DeliveryPeriodForOffer} from '../../../shared/offer-claim/model/DeliveryPeriodForOffer';
import {OfferType} from '../../../shared/offer-claim/model/offer-type.enum';
import {CoverageTransactionsService} from '../../coverage-transactions.service';
import {Market} from '../../../shared/offer-claim/model/market.enum';
import {OfferPeriod} from '../../../shared/offer-claim/model/offer-period.enum';
import moment, {Duration, Moment} from 'moment';
import {Resolution} from '../../../shared/time-series/model/resolution.enum';
import {NgBDatePickerConvertService} from '../../../shared/services/ngb.datepicker.convert.service';
import {AbstractControl} from '@angular/forms';
import {OfferClaimDto} from '../../../shared/offer-claim/model/OfferClaimDto';
import {
    OfferClaimWithTimeSeries,
    OfferClaimWithTimeSeriesBuilder
} from '../../../shared/offer-claim/model/OfferClaimWithTimeSeries';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {TimeSeriesService} from '../../../shared/time-series/time-series.service';
import {TimeInterval} from '../../../shared/time-series/time-series-interval/time-interval.model';
import {ExcelType} from '../../../shared/time-series/model/excel-type.enum';
import {TimeSeriesType} from '../../../shared/time-series/model/time-series-type.enum';
import {
    ProductAvailabilityModel,
    ProductAvailabilityQueryModel
} from '../../../shared/daily-rates/model/product-availability.model';
import {DeliveryPeriod} from '../../../shared/dto/delivery.periods';
import {ValidationException, Violation} from '../../../shared/dto/validation.exception.model';
import {DailyRatesDto} from '../../../shared/daily-rates/model/DailyRatesDto';
import {DailyPricesService} from '../../../shared/services/daily-prices.service';
import {BusinessCalendarService} from '../../../shared/services/business-calendar.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import {DateUtil} from "../../../core/util/date.util";
import _ from 'lodash';
import {DeliveryPeriodDto} from '../../../shared/dto/delivery-period-dto';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'new-claim-dialog',
  templateUrl: 'new-claim-dialog.component.html',
  styleUrls: ['new-claim-dialog.component.scss']
})
export class NewClaimDialogComponent implements OnInit, OnDestroy {

  public readonly DEBOUNCE_TIME: number = 500;
  public readonly PurchaseStatus: typeof PurchaseStatus = PurchaseStatus;

  @Output()
  public modalClose: EventEmitter<boolean> = new EventEmitter(); // emits true when saved, false when closed
  @Input()
  public set dialogConfig(config: NewClaimDialogConfig) {
    this.config = config;
    this.fs.initForm(this.config);
    this.initDisabledDeliveryPeriodIds();
  }

  public config: NewClaimDialogConfig;

  public agreementParams: HttpParams;
  public isSaving: boolean = false;
  public errorMessages: string[] = [];
  public warningMessages: string[] = [];
  public hasIlliquidPeriod: boolean = false;
  public otcMarketClosed: boolean;

  private onTheFlyValidationError: boolean = true;
  private disabledDeliveryPeriodIds: number[] = [];
  private destroy: Subject<void> = new Subject<void>();

  private calculateQuantitiesDebounce: () => void = _.debounce(this.calculateQuantities, this.DEBOUNCE_TIME);

  constructor(public fs: NewClaimDialogFormStateService,
              private translate: TranslateService,
              private coverageTransactionsService: CoverageTransactionsService,
              private dateConverter: NgBDatePickerConvertService,
              private dialogService: DialogService,
              private timeSeriesService: TimeSeriesService,
              private dailyPricesService: DailyPricesService,
              private businessCalendarService: BusinessCalendarService,
              public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.subscribeFormChanges();
    this.initFormValues();
    this.fs.setDisabledStates();
    this.clearNavigateBack();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public closeDialog(savedItem: boolean): void {
    this.modalClose.emit(savedItem);
    this.fs.destroyForm();
  }

  public save(): void {
    this.doSave(OfferClaimWithTimeSeriesBuilder.builder()
      .withOfferClaim(this.createOfferClaim())
      .withQuantityTimeSeries(this.fs.curveImportControl.value)
      .build());
  }

  public saveDisabled(): boolean {
    if (this.fs.isAdminMode()) {
      return !this.fs.form.valid || this.isSaving;
    }

    return !this.fs.form.valid || this.isSaving || this.onTheFlyValidationError || this.hasError();
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (event.key === '-' || (this.translate.currentLang === 'hu' && event.key === '.')) {
      event.preventDefault();
    }
  }

  public exportTemplate(): void {
    const deliveryPeriod: DeliveryPeriodForOffer & {deliveryPeriod: DeliveryPeriodDto} = (this.fs.deliveryPeriodControl.value);
    const interval: { start: Moment, end: Moment } = this.getPeriodInterval(
      moment(deliveryPeriod.deliveryPeriod.startTime).year(),
      this.fs.periodControl.value,
      this.fs.periodNumberControl.value
    );
    const timeInterval: TimeInterval = TimeInterval.builder()
      .withStart(interval.start)
      .withEnd(interval.end)
      .withResolution(Resolution.QUARTER_HOURLY)
      .build();

    this.timeSeriesService.export(
      ExcelType.VECTOR,
      TimeSeriesType.OFFER_CLAIM_CONSUMPTION,
      timeInterval,
      false,
      {agreementId: String(deliveryPeriod.deliveryPeriod.agreementId)}
    );
  }

  public setTimeSeriesUploadParams(): void {
    const deliveryPeriod: DeliveryPeriodForOffer & {deliveryPeriod: DeliveryPeriodDto} = (this.fs.deliveryPeriodControl.value);
    const interval: { start: Moment, end: Moment } = this.getPeriodInterval(
      moment(deliveryPeriod.deliveryPeriod.startTime).year(),
      this.fs.periodControl.value,
      this.fs.periodNumberControl.value
    );
    this.agreementParams = new HttpParams()
      .set('deliveryStart', interval.start.toISOString())
      .set('deliveryEnd', interval.end.subtract(1, 'day').toISOString())
      .set('agreementId', String(deliveryPeriod.deliveryPeriod.agreementId));
  }

  public onUpload(response: {
    file: File,
    success: boolean,
    response?: ExcelImportResult,
    error?: HttpErrorResponse
  }): void {
    this.resetQuantitiesFormControl();
    if (response.success) {
      this.fs.curveImportControl.setValue(response.response.timeSeries);
      this.coverageTransactionsService.getAverageQuantity(response.response.timeSeries).subscribe((averageQuantity: number): void => {
        this.fs.quantityControl.setValue(averageQuantity);
      });
    } else {
      this.fs.curveImportControl.reset();
    }
  }

  public deliveryPeriodDisabled(deliveryPeriod: DeliveryPeriodForOffer): boolean {
    return this.disabledDeliveryPeriodIds.indexOf(deliveryPeriod.deliveryPeriod.id) !== -1;
  }

  public hasError(adminCheck: boolean = true): boolean {
    if (adminCheck && this.fs.isAdminMode()) {
      return false;
    }

    return this.errorMessages.length > 0 || this.missingClicks();
  }

  public hasWarning(): boolean {
    if (this.fs.isAdminMode()) {
      // in case of admin, all error is warning
      return this.warningMessages.length > 0 || this.hasError(false) || this.hasIlliquidPeriod || this.offerQuantityNonStandardStep() || this.otcMarketClosed;
    }

    return this.warningMessages.length > 0 || this.hasIlliquidPeriod || this.offerQuantityNonStandardStep() || this.otcMarketClosed;
  }

  public missingClicks(): boolean {
    if (!this.fs.isDeliveryPeriodDetermined() || this.fs.isCashOut()) {
      return false;
    }
    const deliveryPeriod: DeliveryPeriodForOffer = (this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer);

    return _.isNil(deliveryPeriod.tranche);
  }

  public offerQuantityNonStandardStep(): boolean {
    if (!this.fs.isDeliveryPeriodDetermined() || this.fs.isStepByStep() || _.isNil(this.fs.quantityControl.value)) {
      return false;
    }

    return this.fs.quantityControl.value % 0.5 !== 0;
  }

  public isDayDisabled: (date: NgbDate) => boolean = (date: NgbDate) => {
    const day: Moment = moment(this.dateConverter.convertToDate(date));
    return !this.businessCalendarService.isDaySelectable(day);
  }

  public isLastMonthOfDeliveryPeriod(): boolean {
    if (!this.fs.deliveryPeriodControl.value) {
      return false;
    }
    const endDate: Moment = moment((this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer).deliveryPeriod.endTime);

    return moment().year() === endDate.year() &&
      moment().month() === endDate.month();
  }

  private setHasIlliquidPeriod(): void {
    if (_.isNil(this.fs.periodNumberControl.value) || _.isNil(this.fs.periodControl.value)) {
      this.hasIlliquidPeriod = false;
      return;
    }

    const deliveryPeriod: DeliveryPeriodForOffer = (this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer);
    const now: Moment = moment();
    const selectedPeriodStart: Moment = this.getPeriodInterval(
      moment(deliveryPeriod.deliveryPeriod.startTime).year(),
      this.fs.periodControl.value,
      this.fs.periodNumberControl.value
    ).start;
    const duration: Duration = moment.duration(selectedPeriodStart.diff(now));

    switch (this.fs.periodControl.value) {
      case OfferPeriod.Y:
        this.hasIlliquidPeriod = duration.asYears() > 2;
        break;
      case OfferPeriod.Q:
        this.hasIlliquidPeriod = duration.asMonths() > 6;
        break;
      case OfferPeriod.M:
        this.hasIlliquidPeriod = duration.asMonths() > 2;
        break;
      case OfferPeriod.W:
        this.hasIlliquidPeriod = duration.asWeeks() > 2;
        break;
      default:
        this.hasIlliquidPeriod = false;
    }
  }

  private createOfferClaim(): OfferClaimDto {
    const offerClaim: OfferClaimDto = this.fs.isModificationMode() ? _.cloneDeep(this.config.claim) : new OfferClaimDto();
    const deliveryPeriod: DeliveryPeriodForOffer = (this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer);

    offerClaim.deliveryPeriod = deliveryPeriod.deliveryPeriod;
    offerClaim.market = this.fs.marketControl.value;
    offerClaim.type = this.fs.typeControl.value;
    offerClaim.period = this.fs.periodControl.value;
    offerClaim.periodNumber = this.fs.periodNumberControl.value;
    if (this.fs.isCustomPeriod()) {
      offerClaim.startDate = this.dateConverter.convertToDate(this.fs.startDateControl.value);
      offerClaim.endDate = this.dateConverter.convertToDate(this.fs.endDateControl.value);
    } else {
      const interval: { start: Moment, end: Moment } = this.getPeriodInterval(
        moment(deliveryPeriod.deliveryPeriod.startTime).year(),
        this.fs.periodControl.value,
        this.fs.periodNumberControl.value
      );
      offerClaim.startDate = interval.start.toDate();
      offerClaim.endDate = interval.end.subtract(1, 'day').toDate();
    }
    offerClaim.quantity = this.fs.quantityControl.value;
    offerClaim.tranche = this.fs.trancheControl.value;
    if (this.fs.isAdminMode()) {
      offerClaim.netPrice = this.fs.netPriceControl.value;
    }
    offerClaim.priceType = this.config.priceType;
    offerClaim.createdByPartner = this.fs.isPartnerMode();
    offerClaim.productType = deliveryPeriod.productType;
    offerClaim.averagingTransaction = this.fs.averagingControl.value;
    if (this.fs.isAveraging()) {
      offerClaim.averagingStartDate = this.dateConverter.convertToDate(this.fs.averagingStartControl.value);
      offerClaim.averagingEndDate = this.dateConverter.convertToDate(this.fs.averagingEndControl.value);
    }
    offerClaim.quantityInTimeSeries = this.fs.curveEnabledControl.value;
    offerClaim.purchaseStatus = this.fs.purchaseStatusControl.value;

    return offerClaim;
  }

  private initFormValues(): void {
    this.initAveraging();
    this.initPartner();
    this.initDeliveryPeriod();
    this.initType();
    this.initPurchaseStatus();
    this.initPrice();
  }

  private initAveraging(): void {
    if (!!this.config.claim) {
      this.fs.averagingControl.setValue(!!this.config.claim.averagingTransaction);
    } else {
      this.fs.averagingControl.setValue(false);
    }
  }

  private initPartner(): void {
    if (this.fs.isAdminMode()) {
      if (!!this.config.partner && !!this.config.partner.all && this.config.partner.all.length > 0) {
        this.fs.partners = this.config.partner.all;
        if (!!this.config.partner.selected) {
          this.fs.partnerControl.setValue(this.config.partner.selected);
        }
      }
    }
  }

  private initDeliveryPeriod(): void {
    if (!!this.config.deliveryPeriod) {
      if (this.fs.isPartnerMode()) {
        if (!!this.config.deliveryPeriod.all && this.config.deliveryPeriod.all.length > 0) {
          this.fs.deliveryPeriods = this.config.deliveryPeriod.all;
        }
      }
      if (!!this.config.deliveryPeriod.selected) {
        this.fs.deliveryPeriodControl.setValue(this.config.deliveryPeriod.selected);
      }
    }
  }

  private initType(): void {
    this.fs.types = [OfferType.BL];
    this.fs.typeControl.setValue(this.fs.types[0]);
  }

  private initMarket(): void {
    forkJoin([
      this.coverageTransactionsService.isMarketOpen(Market.OTC, null, null),
      this.coverageTransactionsService.isMarketOpen(Market.HUDEX, this.getHudexAccess(), this.getHudexD1AccessAccess()),
    ]).subscribe((accesses: [boolean, boolean]): void => {
      const otcAccess: boolean = accesses[0];
      const hudexAccess: boolean = accesses[1];

      const markets: Market[] = [Market.OTC];
      if (hudexAccess || this.fs.isAdminMode()) {
        markets.push(Market.HUDEX);
      }

      this.otcMarketClosed = !otcAccess;

      this.fs.markets = markets;
      this.filterMarkets();
      this.setMarketIfUnique();
    });
    this.coverageTransactionsService
      .isMarketOpen(Market.HUDEX, this.getHudexAccess(), this.getHudexD1AccessAccess())
      .subscribe((hudexAccess: boolean): void => {
        const markets: Market[] = [Market.OTC];
        if (hudexAccess || this.fs.isAdminMode()) {
          markets.push(Market.HUDEX);
        }

        this.fs.markets = markets;

        this.filterMarkets();
        this.setMarketIfUnique();
      });
  }

  private initPeriod(initChain: boolean = false): void {
    this.resetQuantitiesFormControl();
    this.resetPeriodFormControl();
    this.resetAvailablePeriods();

    let getDailyRateNames: Observable<string[]>;
    const partner: PartnerWithDeliveryPeriods = (this.fs.partnerControl.value as PartnerWithDeliveryPeriods);
    const deliveryPeriod: DeliveryPeriodForOffer = (this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer);
    const year: number = moment(deliveryPeriod.deliveryPeriod.startTime).year();

    if (this.fs.isAdminMode()) {
      getDailyRateNames = this.coverageTransactionsService.getDailyRateNamesForAdminOfferClaim(deliveryPeriod.deliveryPeriod.id);
    } else {
      getDailyRateNames = this.coverageTransactionsService.getDailyRateNamesForOfferClaim(year);
    }

    getDailyRateNames.subscribe((names: string[]): void => {
      this.fs.periodPeriodNumberMap = this.dailyRateNamesToPeriodPeriodNumberMap(names);
      this.fs.periods = Array.from(this.fs.periodPeriodNumberMap.keys());
      if (this.fs.isAdminMode() && this.fs.isCashOut() && this.fs.periods.indexOf(OfferPeriod.CUSTOM) === -1) {
        this.fs.periods.push(OfferPeriod.CUSTOM);
      }
      this.filterPeriods();
      if (initChain && this.fs.isModificationMode() && this.fs.periodsFiltered.indexOf(this.config.claim.period) !== -1) {
        this.fs.periodControl.setValue(this.config.claim.period);
      } else if (this.fs.isDailyPriceMode()) {
        const dailyRateData: DailyRateData = this.getDailyRateData(this.config.dailyRate);
        initChain = true;
        this.fs.periodControl.setValue(dailyRateData.period);
      } else {
        // this.setPeriodIfUnique();
      }
      this.fs.setPeriodDisabledState();

      if (initChain) {
        this.initPeriodNumber(initChain);
      }
    });
  }

  private initPeriodNumber(initChain: boolean = false): void {
    this.fs.periodNumbers = this.fs.periodPeriodNumberMap.get(this.fs.periodControl.value);
    if (this.fs.isModificationMode() && initChain) {
      this.fs.periodNumberControl.setValue(this.config.claim.periodNumber);
    } else if (this.fs.isDailyPriceMode()) {
      const dailyRateData: DailyRateData = this.getDailyRateData(this.config.dailyRate);
      this.fs.periodNumberControl.setValue(dailyRateData.periodNumber);
    } else {
      if (this.fs.periodControl.value === OfferPeriod.Y) {
        this.setPeriodNumberIfUnique();
      }
    }
    this.fs.setPeriodNumberDisabledState();

    if (initChain) {
      this.initQuantityAndTranche();
      this.initAveragingStartEndDate();
    }
  }

  private initQuantityAndTranche(): void {
    if (this.fs.isModificationMode()) {
      this.fs.quantityControl.setValue(this.config.claim.quantity);
      this.fs.trancheControl.setValue(this.config.claim.tranche);
    }
  }

  private initAveragingStartEndDate(): void {
    if (this.fs.isAveraging()) {
      this.fs.averagingStartControl.setValue(this.dateConverter.convertFromDate(this.config.claim.averagingStartDate));
      this.fs.averagingEndControl.setValue(this.dateConverter.convertFromDate(this.config.claim.averagingEndDate));
    }
  }

  private initPurchaseStatus(): void {
    if (this.fs.isModificationMode()) {
      this.fs.purchaseStatusControl.setValue(this.config.claim.purchaseStatus);
    } else if (this.fs.isPartnerMode()) {
      this.fs.purchaseStatusControl.setValue(PurchaseStatus.BUY);
    }
  }

  private initPrice(): void {
    if (this.fs.isDailyPriceMode() && !this.config.dailyRate.withoutPrice) {
      const dailyRateData: DailyRateData = this.getDailyRateData(this.config.dailyRate);
      this.fs.netPriceControl.setValue(dailyRateData.price);
    }
    this.fs.setPriceDisabledState();
  }

  private initDisabledDeliveryPeriodIds(): void {
    this.disabledDeliveryPeriodIds = this.config.disabledDeliveryPeriods
      .map((deliveryPeriodForOffer: DeliveryPeriodForOffer): number => deliveryPeriodForOffer.deliveryPeriod.id);
  }

  private subscribeFormChanges(): void {
    this.subscribeAveragingChanges();
    this.subscribePartnerChanges();
    this.subscribeDeliveryPeriodChanges();
    this.subscribePeriodChanges();
    this.subscribePeriodNumberChanges();
    this.subscribeAveragingPeriodAndPeriodNumberChanges();
    this.subscribeStartEndDateChanges();
    this.subscribeQuantityAndTrancheChanges();
    this.subscribeCurveEnabledChanges();
    this.subscribeFormGroupChanges();
  }

  private subscribeAveragingChanges(): void {
    this.fs.averagingControl.valueChanges
      .pipe(filter((val: any): boolean => !_.isNil(val)), distinctUntilChanged(), takeUntil(this.destroy))
      .subscribe((averaging: boolean): void => {
        this.filterMarkets();
        this.setMarketIfUnique();
        this.filterPeriods();
        // this.setPeriodIfUnique();
        if (averaging) {
          this.fs.setAveragingStartEndDateDisabled();
          this.businessCalendarService.loadWorkdaysAndHolidays();
        }
      });
  }

  private subscribePartnerChanges(): void {
    this.fs.partnerControl.valueChanges
      .pipe(filter((val: any): boolean => !_.isNil(val)), distinctUntilChanged(), takeUntil(this.destroy))
      .subscribe((partner: PartnerWithDeliveryPeriods): void => {
        this.fs.deliveryPeriods = partner.deliveryPeriods;
        this.fs.deliveryPeriodControl.setValue(null);
      });
  }

  private subscribeDeliveryPeriodChanges(): void {
    this.fs.deliveryPeriodControl.valueChanges
      .pipe(filter((val: any): boolean => !_.isNil(val)), distinctUntilChanged(), takeUntil(this.destroy))
      .subscribe((deliveryPeriod: DeliveryPeriodForOffer): void => {
        this.setMinMaxDates();
        this.initMarket();
        this.initPeriod(true);
        this.fs.curveEnabledControl.reset();
        this.fs.setPeriodNumberDisabledState();
        this.setHasIlliquidPeriod();
      });
  }

  private subscribePeriodChanges(): void {
    this.fs.periodControl.valueChanges
      .pipe(filter((val: any): boolean => !_.isNil(val)), takeUntil(this.destroy))
      .subscribe((period: OfferPeriod): void => {
        this.resetQuantitiesFormControl();
        this.fs.periodNumberControl.reset();
        if (period !== OfferPeriod.CUSTOM) {
          this.initPeriodNumber();
        } else {
          this.fs.setPeriodNumberDisabledState();
        }
      });
  }

  private subscribePeriodNumberChanges(): void {
    this.fs.periodNumberControl.valueChanges
      .pipe(filter((val: any): boolean => !_.isNil(val)), takeUntil(this.destroy))
      .subscribe((periodNumber: number): void => {
        this.calculateQuantitiesDebounce();
      });
  }

  private subscribeAveragingPeriodAndPeriodNumberChanges(): void {
    combineLatest(this.fs.averagingControl.valueChanges, this.fs.periodControl.valueChanges, this.fs.periodNumberControl.valueChanges)
      .pipe(
        filter(([averaging, period, periodNumber]) => !_.isNil(period) && !_.isNil(periodNumber)),
        debounceTime(this.DEBOUNCE_TIME),
        takeUntil(this.destroy)
      )
      .subscribe(([averaging, period, periodNumber]): void => {
        if (averaging) {
          this.setAveragingMinMaxDates();
          this.fs.setAveragingStartEndDateDisabled();
        }
        this.setHasIlliquidPeriod();
      });
  }

  private subscribeStartEndDateChanges(): void {
    combineLatest(this.fs.startDateControl.valueChanges, this.fs.endDateControl.valueChanges)
      .pipe(
        filter(([startDate, endDate]): boolean => !_.isNil(startDate) && !_.isNil(endDate)),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      ).subscribe(([startDate, endDate]): void => {
      this.calculateQuantitiesDebounce();
    });
  }

  private subscribeQuantityAndTrancheChanges(): void {
    combineLatest(this.fs.quantityControl.valueChanges, this.fs.trancheControl.valueChanges)
      .pipe(
        filter(([quantity, tranche]): boolean => !_.isNil(quantity) || !_.isNil(tranche)),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      ).subscribe(([quantity, tranche]): void => {
      this.calculateQuantitiesDebounce();
    });
  }

  private subscribeCurveEnabledChanges(): void {
    this.fs.curveEnabledControl.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe((enabled: boolean): void => {
        if (enabled) {
          this.fs.purchaseStatusControl.setValue(PurchaseStatus.BUY);
        }
        this.fs.setPurchaseStatusDisabledState();
        this.fs.setQuantityDisabledState();
        if (enabled) {
          this.fs.quantityControl.setValue(null);
        }
        this.fs.updateFormValidity();
      });
  }

  private subscribeFormGroupChanges(): void {
    this.fs.form.valueChanges
      .pipe(
        debounceTime(this.DEBOUNCE_TIME),
        distinctUntilChanged((prev, curr): boolean => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy)
      ).subscribe((controls: { [controlName: string]: AbstractControl }): void => {
      this.fs.updateFormValidity();
      this.errorMessages = [];
      this.warningMessages = [];
      if (this.fs.form.valid) {
        this.onTheFlyValidation();
      }
    });
  }

  private getHudexAccess(): boolean {
    if (!this.fs.isDeliveryPeriodDetermined()) {
      return false;
    }
    const hudexD: Boolean = (this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer).hudexD;

    return _.isNil(hudexD) ? false : hudexD.valueOf();
  }

  private getHudexD1AccessAccess(): boolean {
    if (!this.fs.isDeliveryPeriodDetermined()) {
      return false;
    }
    const hudexDOne: Boolean = (this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer).hudexDOne;

    return _.isNil(hudexDOne) ? false : hudexDOne.valueOf();
  }

  private dailyRateNamesToPeriodPeriodNumberMap(dailyRates: string[]): Map<OfferPeriod, number[]> {
    const periodPeriodNumberMap: Map<OfferPeriod, number[]> = new Map<OfferPeriod, number[]>();

    const offerPeriods: OfferPeriod[] = dailyRates.map((dailyRate: string): OfferPeriod => {
      if (dailyRate.length === 4) {
        return OfferPeriod.Y;
      }
      return dailyRate.slice(4, 5) as OfferPeriod;
    }).filter((value: OfferPeriod, index: number, self: OfferPeriod[]): boolean => self.indexOf(value) === index);

    offerPeriods.forEach((offerPeriod: OfferPeriod): void => {
      if (offerPeriod === OfferPeriod.Y) {
        periodPeriodNumberMap.set(OfferPeriod.Y, [1]);
      } else {
        const periodNumbers: number[] = dailyRates
          .filter((dailyRate: string): boolean => dailyRate.indexOf(offerPeriod.toString()) !== -1)
          .map((dailyRate: string): number => +dailyRate.slice(5));
        periodPeriodNumberMap.set(offerPeriod, periodNumbers);
      }
    });

    return periodPeriodNumberMap;
  }

  private calculateQuantities(): void {
    if (!this.fs.isPeriodSet() || !this.fs.isQuantitySet()) {
      this.resetCalculatedQuantitiesFormControl();
      return;
    }

    if (this.fs.isCashOut()) {
      this.calculateMwhCO();
    } else {
      this.calculateMwhAndPercentSBS();
    }
  }

  private calculateMwhAndPercentSBS(): void {
    const deliveryPeriod: DeliveryPeriodForOffer = (this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer);
    const interval: { start: Moment, end: Moment } = this.getPeriodInterval(
      moment(deliveryPeriod.deliveryPeriod.startTime).year(),
      this.fs.periodControl.value,
      this.fs.periodNumberControl.value
    );

    this.coverageTransactionsService.getQuantityFromTranche({
      deliveryPeriodId: deliveryPeriod.deliveryPeriod.id,
      tranche: this.fs.trancheControl.value,
      timeInterval: {
        start: interval.start,
        end: interval.end,
        resolution: Resolution.MONTHLY
      }
    }).subscribe((quantityMWh: number): void => {
      this.fs.quantityMwhControl.setValue(quantityMWh, NewClaimDialogFormStateService.PREVENT_EVENT_BUBBLING);
      this.fs.percentControl.setValue(
        +(this.fs.trancheControl.value * 100 / deliveryPeriod.tranche).toFixed(2),
        NewClaimDialogFormStateService.PREVENT_EVENT_BUBBLING
      );
    });
  }

  private calculateMwhCO(): void {
    const deliveryPeriod: DeliveryPeriodForOffer = (this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer);
    const interval: { start: Moment, end: Moment } = this.getPeriodInterval(
      moment(deliveryPeriod.deliveryPeriod.startTime).year(),
      this.fs.periodControl.value,
      this.fs.periodNumberControl.value
    );
    const duration: Duration = moment.duration(interval.end.diff(interval.start));
    const durationInHours: number = duration.asHours();

    this.fs.quantityMwhControl.setValue(
      (durationInHours * (this.fs.quantityControl.value || 0)),
      NewClaimDialogFormStateService.PREVENT_EVENT_BUBBLING
    );
  }

  private getPeriodInterval(year: number, period: OfferPeriod, periodNumber: number): { start: Moment, end: Moment } {
    let yearStart: Moment = moment({year: year});
    let start: Moment;
    let end: Moment;

    switch (period) {
      case OfferPeriod.Y:
        start = moment(yearStart);
        end = moment(yearStart).add(1, 'year');
        break;
      case OfferPeriod.Q:
        start = moment(yearStart).add(periodNumber - 1, 'quarter');
        end = moment(yearStart).add(periodNumber, 'quarter');
        break;
      case OfferPeriod.M:
        start = moment(yearStart).add(periodNumber - 1, 'month');
        end = moment(yearStart).add(periodNumber, 'month');
        break;
      case OfferPeriod.W:
        if (yearStart.week() !== 1) {
          yearStart = yearStart.startOf('week').add(1, 'week');
        }
        start = moment(yearStart).add(periodNumber - 1, 'week');
        end = moment(yearStart).add(periodNumber, 'week');
        break;
      case OfferPeriod.CUSTOM:
        start = moment(this.dateConverter.convertToDate(this.fs.startDateControl.value));
        end = moment(this.dateConverter.convertToDate(this.fs.endDateControl.value)).add(1, 'day');
        break;
    }

    return {start, end};
  }

  private doSave(offerClaim: OfferClaimWithTimeSeries): void {
    let saveOfferClaim: Observable<OfferClaimDto>;
    this.isSaving = true;

    if (this.fs.isModificationMode()) {
      saveOfferClaim = this.coverageTransactionsService.updateClaim(offerClaim);
    } else {
      saveOfferClaim = this.coverageTransactionsService.createClaim(offerClaim);
    }

    saveOfferClaim.subscribe((saved: OfferClaimDto): void => {
      this.dialogService.saveSuccess();
      this.closeDialog(true);
      this.isSaving = false;
    }, (errors: HttpErrorResponse): void => {
      this.processError(errors);
      this.isSaving = false;
    });
  }

  private onTheFlyValidation(): void {
    this.onTheFlyValidationError = true;
    this.coverageTransactionsService.validateClaim(OfferClaimWithTimeSeriesBuilder.builder()
      .withOfferClaim(this.createOfferClaim())
      .withQuantityTimeSeries(this.fs.curveImportControl.value)
      .build())
      .subscribe((): boolean => this.onTheFlyValidationError = false, (error: HttpErrorResponse): void => this.processError(error));
  }

  private setMinMaxDates(): void {
    const deliveryPeriod: DeliveryPeriod = (this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer).deliveryPeriod;
    this.fs.startMinDate = this.dateConverter.convertFromDate(moment(deliveryPeriod.startTime).toISOString());
    this.fs.startMaxDate = this.dateConverter.convertFromDate(moment(deliveryPeriod.endTime).toISOString());
    this.fs.endMinDate = this.dateConverter.convertFromDate(moment(deliveryPeriod.startTime).toISOString());
    this.fs.endMaxDate = this.dateConverter.convertFromDate(moment(deliveryPeriod.endTime).toISOString());
  }

  private setAveragingMinMaxDates(): void {
    const deliveryPeriod: DeliveryPeriodForOffer = (this.fs.deliveryPeriodControl.value as DeliveryPeriodForOffer);
    this.coverageTransactionsService.getProductAvailability({
      period: this.fs.periodControl.value,
      year: moment(deliveryPeriod.deliveryPeriod.startTime).year(),
      periodNumber: this.fs.periodNumberControl.value
    } as ProductAvailabilityQueryModel)
      .subscribe((product: ProductAvailabilityModel): void => {
        if (this.config.mode !== NewClaimDialogMode.ADMIN_MODE) {
          this.fs.averagingStartMinDate = this.dateConverter.convertFromDate(moment(product.availableFrom).toISOString());
          this.fs.averagingStartMaxDate = this.dateConverter.convertFromDate(moment(product.availableTo).subtract(1, 'day').toISOString());
          this.fs.averagingEndMinDate = this.dateConverter.convertFromDate(moment(product.availableFrom).toISOString());
          this.fs.averagingEndMaxDate = this.dateConverter.convertFromDate(moment(product.availableTo).subtract(1, 'day').toISOString());
        } else {
          this.fs.averagingStartMinDate = this.dateConverter.convertFromDate(DateUtil.MIN_DATE);
          this.fs.averagingStartMaxDate = this.dateConverter.convertFromDate(DateUtil.MAX_DATE);
          this.fs.averagingEndMinDate = this.dateConverter.convertFromDate(DateUtil.MIN_DATE);
          this.fs.averagingEndMaxDate = this.dateConverter.convertFromDate(moment(product.availableTo).subtract(1, 'day').toISOString());
        }
      });
  }

  private setMarketIfUnique(): void {
    if (this.fs.marketsFiltered.length === 1 && this.fs.isPartnerMode()) {
      this.fs.marketControl.setValue(this.fs.marketsFiltered[0]);
    }
    this.fs.setMarketDisabledState();
  }

  private setPeriodIfUnique(): void {
    if (this.fs.periodsFiltered.length === 1) {
      this.fs.periodControl.setValue(this.fs.periodsFiltered[0]);
    }
  }

  private setPeriodNumberIfUnique(): void {
    if (!_.isNil(this.fs.periodNumbers) && this.fs.periodNumbers.length === 1) {
      this.fs.periodNumberControl.setValue(this.fs.periodNumbers[0]);
    }
  }

  private filterMarkets(): void {
    this.fs.marketsFiltered = this.fs.markets.filter((market: Market): boolean => {
      if (market === Market.HUDEX) {
        return this.fs.isAdminMode() && !this.fs.isAveraging();
      }

      return true;
    });
  }

  private filterPeriods(): void {
    this.fs.periodsFiltered = this.fs.periods.filter((period: OfferPeriod): boolean => {
      if (period === OfferPeriod.CUSTOM) {
        return !this.fs.isAveraging();
      }

      return true;
    });
  }

  private resetAvailablePeriods(): void {
    this.fs.periodPeriodNumberMap = new Map<OfferPeriod, number[]>();
    this.fs.periodNumbers = [];
    this.fs.periods = [];
    this.filterPeriods();
  }

  private resetPeriodFormControl(): void {
    this.fs.startDateControl.reset();
    this.fs.endDateControl.reset();
    this.fs.periodNumberControl.reset();
    this.fs.periodControl.reset();
  }

  private resetQuantitiesFormControl(): void {
    this.fs.quantityControl.reset();
    this.fs.trancheControl.reset();
    this.resetCalculatedQuantitiesFormControl();
  }

  private resetCalculatedQuantitiesFormControl(): void {
    this.fs.quantityMwhControl.reset(null, NewClaimDialogFormStateService.PREVENT_EVENT_BUBBLING);
    this.fs.percentControl.reset(null, NewClaimDialogFormStateService.PREVENT_EVENT_BUBBLING);
  }

  private processError(error: HttpErrorResponse): void {
    if (error.status !== 422) {
      return;
    }

    const errorObject: ValidationException = error.error;
    if (!errorObject.hidden) {
      if (!errorObject.violations || errorObject.violations.length === 0) {
        this.errorMessages.push(this.translate.instant('validation.unknown'));
      } else {
        errorObject.violations.forEach((violation: Violation) => {
          if (violation.warning) {
            this.warningMessages.push(this.translate.instant(`validation.${violation.errorCode}`, violation.args));
          } else {
            this.errorMessages.push(this.translate.instant(`validation.${violation.errorCode}`, violation.args));
          }
        });
      }
    }
    if (this.errorMessages.length === 0) {
      this.onTheFlyValidationError = false;
    }
  }

  private getDailyRateData(dailyRate: DailyRatesDto): DailyRateData {
    const map: Map<OfferPeriod, number[]> = this.dailyRateNamesToPeriodPeriodNumberMap([this.config.dailyRate.name]);
    const period: OfferPeriod = Array.from(map.keys())[0];

    return {
      period: period,
      periodNumber: Array.from(map.get(period))[0],
      price: dailyRate.currentPrice
    };
  }

  private clearNavigateBack(): void {
    if (this.dailyPricesService.navigateBack === 'buyingTab') {
      this.dailyPricesService.navigateBack = null;
    }
  }
}
