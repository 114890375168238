<div class="select-wrapper w-auto cursor--pointer">
    <div class="d-flex flex-row align-items-center h-100 position-relative mr-4 ml-3 cursor--pointer">
        <span class="font-weight-bold">{{ 'coverageTransactions.tabs.overview.interval' | translate }}</span>
        <span *ngIf="deliveryPeriod">
            <span class="font-weight-bold">:</span>
            {{ deliveryPeriod.startTime | cezDate }} - {{ deliveryPeriod.endTime | cezDate }}
        </span>
    </div>

    <select class="form-control"
            [(ngModel)]="deliveryPeriod"
            (ngModelChange)="onDeliveryPeriodChanged()">
        <option *ngFor="let dp of deliveryPeriods" [ngValue]="dp">
            {{ dp.startTime | cezDate }} - {{ dp.endTime | cezDate }}
        </option>
    </select>
</div>

<ng-container *ngIf="selectedOverview && selectedOverview.product; else loadingProductType">
    <div>
        <span>{{ 'agreement.agreementData.services.productType' | translate }}: </span>{{ selectedOverview.product }}
    </div>
</ng-container>
<ng-template #loadingProductType>
    <div>
        <span>{{ 'agreement.agreementData.services.productType' | translate }}: </span><mat-spinner class="transaction-bar-spinner float-right" diameter="20">Loading...</mat-spinner>
    </div>
</ng-template>

<ng-container *ngIf="selectedOverview && selectedOverview.quantity; else loadingQuantity">
    <div>
        <span>{{ 'agreement.agreementData.quantityExpected' | translate }}: </span>{{ selectedOverview.quantity | number}}
    </div>
</ng-container>
<ng-template #loadingQuantity>
    <div>
        <span>{{ 'agreement.agreementData.quantityExpected' | translate }}: </span><mat-spinner class="float-right" diameter="20">Loading...</mat-spinner>
    </div>
</ng-template>

<ng-container *ngIf="selectedOverview && selectedOverview.mtmUnitPrice; else loadingUnitPrice">
    <div>
        <span>{{ 'agreement.agreementData.mtmUnitPrice' | translate }}: </span>{{ selectedOverview.mtmUnitPrice | number: '1.2-2'}}
    </div>
</ng-container>
<ng-template #loadingUnitPrice>
    <div>
        <span>{{ 'agreement.agreementData.mtmUnitPrice' | translate }}: </span><mat-spinner class="float-right" diameter="20">Loading...</mat-spinner>
    </div>
</ng-template>

<ng-container *ngIf="selectedOverview && selectedOverview.mtmUnitPrice; else loadingPosition">
    <div>
        <span>{{ 'agreement.agreementData.position' | translate }}: </span>
        <div class="progress mw-150">
            <div class="progress-bar" role="progressbar" [progressbar]="selectedOverview.progressBarConfig"></div>
        </div>
    </div>
</ng-container>
<ng-template #loadingPosition>
    <div style="min-width: 200px">
        <span>{{ 'agreement.agreementData.position' | translate }}: </span>
        <mat-spinner class="float-right" diameter="20">Loading...</mat-spinner>
    </div>
</ng-template>
