<div mat-dialog-title class="modal-header">
    <button mat-dialog-close aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
    <h2>{{data.title}}</h2>
</div>

<div mat-dialog-content class="modal-body bottom-border">
    <p>{{data.message}}</p>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial class="cez-color--dark">{{'common.action.cancel' | translate}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">{{'common.action.yes' | translate}}</button>
</div>
