import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject, timer} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {DailyRatesDto} from '../daily-rates/model/DailyRatesDto';
import {DailyPricesState, DailyPricesStateChange} from '../dto/daily-prices-state.model';
import {CoverageTransactionsService} from '../../coverage-transactions/coverage-transactions.service';
import {DailyPriceStateChange} from '../daily-rates/model/DailyPriceStateChange';

@Injectable({
  providedIn: 'root'
})
export class DailyPricesService {

  public dailyPricesStateChange: BehaviorSubject<DailyPriceStateChange> = new BehaviorSubject<DailyPriceStateChange>({type: null, updatedDailyRates: []});
  public changedSelectedRate: Subject<DailyRatesDto> = new Subject<DailyRatesDto>();
  public changedSelectedRateForPriceTracker: Subject<DailyRatesDto> = new Subject<DailyRatesDto>();
  public changedSelectedRateForCart: Subject<DailyRatesDto> = new Subject<DailyRatesDto>();
  public selectedRate: DailyRatesDto;
  public dailyPricesState: DailyPricesState;
  public remainTime: number = 0;
  public navigateTo: string = '';
  public navigateBack: string = '';

  private destroyRemainTimeSubscription: Subject<void> = new Subject();

  constructor(private coverageTransactionService: CoverageTransactionsService) { }

  public initDailyPrices(): void {
    this.coverageTransactionService.getDailyState().subscribe((state: DailyPricesState) =>
      this.handleDailyPriceStateChange({dailyPricesState: state} as DailyPricesStateChange));
    this.selectedRate = null;
  }

  public setSelectedRate(rate: DailyRatesDto): void {
    this.selectedRate = rate;
    this.changedSelectedRate.next(rate);
  }

  public setSelectedRateForPriceTracker(rate: DailyRatesDto): void {
    this.selectedRate = rate;
    this.changedSelectedRateForPriceTracker.next(rate);
  }

  public setSelectedRateForCart(rate: DailyRatesDto): void {
    this.selectedRate = rate;
    this.changedSelectedRateForCart.next(rate);
  }

  public handleDailyPriceStateChange(stateChangeEvent: DailyPricesStateChange): void {
    const state: DailyPricesState | null = stateChangeEvent.dailyPricesState;
    this.dailyPricesState = state;
    if (state) {
      if (state.active && state.remainTime && +state.remainTime > 0) {
        this.initRemainTimeCounter(state.remainTime);
      }
      this.dailyPricesStateChange.next({
        type: state.dailyRatesType,
        updatedDailyRates: stateChangeEvent.updatedDailyRates || []
      });
    } else {
      this.resetRemainTimeCounter();
      this.dailyPricesStateChange.next({
        type: null,
        updatedDailyRates: []
      });
    }
  }

  public resetRemainTimeCounter(): void {
    this.destroyRemainTimeSubscription.next();
    this.destroyRemainTimeSubscription.complete();
    this.destroyRemainTimeSubscription = new Subject();
    this.remainTime = 0;
  }

  private initRemainTimeCounter(remainTime: number): void {
    this.resetRemainTimeCounter();
    this.remainTime = remainTime;

    timer(0, 1000)
      .pipe(
        takeUntil(this.destroyRemainTimeSubscription),
        map((counter: number) => (remainTime - counter) > 0 ? remainTime - counter : 0)
      ).subscribe((rt: number) => this.remainTime = rt);
  }
}
