<div mat-dialog-title class="modal-header">
    <button mat-dialog-close aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
    <h2>{{data.title | translate}}</h2>
</div>
<div mat-dialog-content class="modal-body bottom-border">
    <div *ngFor="let detail of data.details"
        class="row">
        <div class="col-6 m-0 text-left"> {{detail.key | translate}}</div>
        <div class="col-6 p-0 m-0 text-left" [innerHTML]="detail.value"></div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [mat-dialog-close] cdkFocusInitial>{{'common.action.ok' | translate}}
    </button>
</div>
