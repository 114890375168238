export interface SseHeartBeatMsg {
    clientId: string;
    partnerId: number;
    sessionIdentifier: string;
}

export enum SseType {
    DAILY_PRICE_STATE_CHANGE = 'DAILY_PRICE_STATE_CHANGE',
    COVERAGE_TRANSACTION_CHANGE = 'COVERAGE_TRANSACTION_CHANGE'
}
