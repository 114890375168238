import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {CountdownModule} from "ngx-countdown";
import {CezPortalSharedModule} from "../../shared";
import {CART_ROUTE} from "./cart.route";
import {CartComponent} from "./cart.component";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from "@angular/material-moment-adapter";

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(CART_ROUTE),
        CountdownModule,
        CezPortalSharedModule
    ],
    declarations: [
        CartComponent
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_MOMENT_DATE_FORMATS
        }
    ]
})
export class CartModule {
}
