export class NumberStringUtil {
    public static convertToNumber(value: string): number {
        if (!value) {
            return null;
        }

        value = value.replace(/[^0-9.,]/g, "").replace(",", ".");
        if (value.lastIndexOf('.') == value.length - 1 || value.lastIndexOf(',') == value.length - 1) {
            value = value + '0';
        }
        return Number(value);
    }
}
