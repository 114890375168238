<div class="modal-header">
    <h4 class="modal-title">
        {{this.data.title | translate}}
    </h4>
    <button mat-dialog-close aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <highcharts-chart [chart]="data.chart"></highcharts-chart>
</div>

<div class="modal-footer">
    <button mat-raised-button mat-dialog-close class="cez-color--dark">
        {{'entity.action.cancel' | translate }}
    </button>
</div>
