import {Injectable, OnDestroy} from '@angular/core';
import {Observable, of, Subject, timer} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SseHeartBeatMsg} from './server-sent-event.model';
import {take, takeUntil, tap} from 'rxjs/operators';
import {StateStorageService} from '../shared/auth';

/**
 * @fileoverview
 * ServerSentEventService manages Server-Sent Events (SSE) subscriptions and client connections.
 * It provides methods for subscribing to specific SSE types, stopping subscriptions, and deleting SSE clients.
 * The service also includes a heartbeat mechanism to ensure the SSE client's connection remains active.
 *
 * @author orcsityn
 */
@Injectable({providedIn: 'root'})
export class ServerSentEventService implements OnDestroy {

    private readonly KEEP_ALIVE_MESSAGE: string = "keep-alive";
    private readonly HEARTBEAT_INTERVAL_MINUTE: number = 3; // Send heartbeat message in every X minutes, to keep connection alive.
    private readonly SSE_ENDPOINT = '/api/sse/subscribe/';

    private clientId: string = null;
    private eventSources: EventSource[] = [];
    private destroy: Subject<void> = new Subject();

    constructor(private httpClient: HttpClient,
                private stateStorageService: StateStorageService) { }

    ngOnDestroy(): void {
        this.unsubscribeFromSse();
    }

    /**
     * Subscribes to a Server-Sent Event (SSE) of the specified type.
     *
     * @template ResponseType - The type of data expected in the SSE response.
     * @param {(data: ResponseType) => void} _success - A callback function to handle successful SSE responses.
     * @param {(error: MessageEvent) => void} [_error] - A callback function to handle SSE errors (optional).
     *
     * @description
     * This method initiates an SSE connection for the specified type and invokes the provided success callback
     * when a message is received. Optionally, an error callback can be provided to handle SSE errors.
     * In addition, it starts the Heartbeat Mechanism if it is not started previously.
     *
     * @example
     * // Subscribe to 'DAILY_PRICE_STATE_CHANGE' SSE type
     * serverSentEventService.subscribe<string>(SseType.DAILY_PRICE_STATE_CHANGE, (response: string) => {
     *   console.log(response);
     * }, (error: MessageEvent) => {
     *   console.error('SSE Error:', error);
     * });
     */
    public subscribe<ResponseType>(_success: (data: ResponseType) => void, _error?: (error: MessageEvent) => void): void {
        const startEventListener: () => void = (): void => {
            if (this.clientId === null) {
                return;
            }

            const eventSource: EventSource = new EventSource(this.SSE_ENDPOINT + this.clientId);
            eventSource.addEventListener('message', (event: MessageEvent): void => {
                if (event.data === this.KEEP_ALIVE_MESSAGE) {
                    return;
                }
                if (event.data === null) {
                    _success(null);
                    return;
                }
                let response: ResponseType;
                try {
                    response = JSON.parse(event.data) as ResponseType;
                } catch (err) {
                    response = event.data as ResponseType;
                }
                _success(response);
            });
            eventSource.onerror = (error: MessageEvent): void => {
                if (!!_error) {
                    _error(error);
                }
            };

            this.eventSources.push(eventSource);
        };

        if (this.clientId == null) {
            this.startHeartbeatMechanism()
                .pipe(take(1))
                .subscribe((): void => startEventListener());
        } else {
            startEventListener();
        }
    }

    /**
     * Deletes the SSE client, terminating all subscriptions and associated resources.
     *
     * @description
     * This method sends a request to the server to remove the SSE client, terminating all active SSE subscriptions
     * and releasing associated resources. After calling this method, the client will no longer receive any SSE events,
     * and its resources will be properly cleaned up on the server side.
     *
     * @example
     * // Delete the SSE client and terminate all subscriptions
     * serverSentEventService.deleteClient();
     */
    public deleteClient(): Observable<void> {
        return this.httpClient.delete<void>('/api/sse/remove/' + this.clientId);
    }

    /**
     * Unsubscribes from all Server-Sent Event (SSE) types, deletes the SSE client, and cleans up associated resources.
     *
     * @description
     * This method closes all active SSE connections for various types, sends a request to the server to remove the SSE client,
     * and cleans up associated resources. After calling this method, the client will no longer receive any SSE events,
     * and its resources will be properly cleaned up on the server side.
     */
    public unsubscribeFromSse(): Observable<void> {
        if (this.eventSources.length > 0) {
            this.eventSources.forEach(source => {
                source.close();
            });
        }
        this.eventSources.splice(0);

        this.destroy.next();
        this.destroy.complete();
        this.destroy = new Subject<void>();

        if (this.clientId === null) {
            return of();
        }
        return this.deleteClient()
            .pipe(tap((): void => this.clientId = null));
    }

    /**
     * Starts the heartbeat mechanism for maintaining the SSE client's connection.
     *
     * @returns {Subject<void>} - A Subject that emits a value on fist successful heartbeat.
     *
     * @description
     * This method initiates a periodic heartbeat mechanism to keep the SSE client's connection alive. It sends
     * periodic heartbeat requests to the server and emits a value on the returned Subject upon successful responses.
     * This ensures that the SSE client remains connected and active.
     */
    private startHeartbeatMechanism(): Subject<void> {
        const response: Subject<void> = new Subject();

        timer(0, this.HEARTBEAT_INTERVAL_MINUTE * 1000 * 60)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => this.heartbeat()
                .pipe(take(1))
                .subscribe(() => response.next()));

        return response;
    }

    /**
     * Sends a heartbeat request to the server to maintain the SSE client's connection.
     *
     * @returns {Subject<void>} - A Subject that emits a value on successful heartbeat response.
     *
     * @description
     * This method sends a heartbeat request to the server, updating the SSE client's status and ensuring its
     * connection remains active. It returns a Subject that emits a value upon successful response from the server.
     * The emitted value indicates a successful heartbeat.
     */
    private heartbeat(): Subject<void> {
        const response: Subject<void> = new Subject();
        const requestMsg: SseHeartBeatMsg = {
            clientId: this.clientId,
            partnerId: this.stateStorageService.getSelectedCompanyId(),
            sessionIdentifier: this.stateStorageService.getSessionIdentifier()
        };

        this.httpClient.post<SseHeartBeatMsg>('/api/sse/heartbeat', requestMsg).subscribe((msg: SseHeartBeatMsg): void => {
            this.clientId = msg.clientId;
            response.next();
        });

        return response;
    }
}
