import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {HedgeConsumptionPlanService} from '../../hedge-consumption-plan/hedge-consumption-plan.service';
import {DeliveryPeriodDto} from '../../../shared/dto/delivery-period-dto';
import {Resolution} from '../../../shared/time-series/model/resolution.enum';
import * as moment from 'moment';

import {
  ChartSide,
  OfferClaimReservationPeriodMap,
  OfferClaimReservationPeriodMapWithAveraging
} from './offer-claim-reservation-period-map.model';
import {CashoutChartType, ProductType} from '../../../shared/agreement-details/agreement-enums.model';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, Subject, Subscriber} from 'rxjs';
import {CoverageTransactionsService} from '../../coverage-transactions.service';
import {OfferClaimDto} from '../../../shared/offer-claim/model/OfferClaimDto';
import {takeUntil} from 'rxjs/operators';
import {SaleStatus} from '../../../shared/offer-claim/model/sale-status.enum';
import {DecimalPipe} from '@angular/common';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {OfferPeriod} from '../../../shared/offer-claim/model/offer-period.enum';
import {ChartDeliveryPeriodUtil} from '../../../shared/delivery-period-list/chart-delivery-period.util';
import {HighchartsService} from '../../../highcharts/highcharts.service';
import {AgreementService} from '../../../shared/services/agreement.service';
import {MaxTotalHedgeCapacity} from '../../../shared/dto/max-total-hedge-capacity.model';
import {PALETTE} from '../../../shared/color-palette/color-palette.constant';
import * as _ from 'lodash';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'jhi-position-report',
    template: '<highcharts-chart [chart]="chart"></highcharts-chart> <ngx-loading class="local-loader" [show]="isLoading" [config]="{fullScreenBackdrop: false}"></ngx-loading>'
})
export class PositionReportComponent implements OnInit, OnDestroy {

    private static readonly MAIN_X_AXIS: string = 'main';
    private static readonly SECONDARY_X_AXIS: string = 'secondary';
    private static readonly EXPECTED_PLAN_STACK: string = 'expectedPlanStack';
    private static readonly COVERAGE_TRANSACTION_STACK: string = 'coverageTransactionStack';
    private static readonly OPEN_BUY_SELL_POSITION_STACK: string = 'openBuySellPositionStack';
    private readonly COLOR_EXPECTED_PLAN: string = '#7a7e82';
    private readonly COLOR_Y: string = '#f24f00';
    private readonly COLOR_Y_AVERAGING: string = '#f57233';
    private readonly COLOR_Q: string = '#ffb700';
    private readonly COLOR_Q_AVERAGING: string = '#ffc533';
    private readonly COLOR_M: string = '#008fdf';
    private readonly COLOR_M_AVERAGING: string = '#33a5e5';
    private readonly COLOR_SPOT: string = '#9A56CF';
    private readonly COLOR_CUSTOM: string = '#e66868';
    private readonly COLOR_OPEN_POSITION: string = '#00BE4D';

    @Input()
    public set deliveryPeriod(deliveryPeriod: DeliveryPeriodDto) {
        this.selectedDeliveryPeriod = deliveryPeriod;
    }

    @Input()
    public set chartType(chartType: CashoutChartType) {
        this.selectedChartType = chartType;
    }

    @Input() offerClaims: Observable<OfferClaimDto[]>;
    @Input() events: Observable<void>;

    public selectedDeliveryPeriod: DeliveryPeriodDto;
    public selectedChartType: CashoutChartType;
    public chart: Chart;
    public isLoading: boolean = false;

    private periodMapAlreadyPurchased: OfferClaimReservationPeriodMap;
    private periodMapNotYetPurchased: OfferClaimReservationPeriodMap;
    private totalQuantity: OfferClaimReservationPeriodMap;
    private expectedPlanMonthlySum: number[] = [];
    private monthsIndexesMap: Map<string, number> = new Map<string, number>();
    private maxTotalHedgeCapacity: MaxTotalHedgeCapacity;
    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(private hedgeConsumptionPlanService: HedgeConsumptionPlanService,
                private coverageTransactionsService: CoverageTransactionsService,
                private translateService: TranslateService,
                private numberPipe: DecimalPipe,
                private dialogService: DialogService,
                private highchartsService: HighchartsService,
                private agreementService: AgreementService) {
    }

    ngOnInit(): void {
        this.initCharts([]);
        this.subscribeOfferClaimChanges();
        this.createMonthsIndexesMap();
        this.subscribeLanguageChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private subscribeOfferClaimChanges(): void {
        if (!!this.offerClaims) {
            this.offerClaims.pipe(takeUntil(this.unsubscribe))
                .subscribe((offerClaimsDtos: OfferClaimDto[]) => {
                    const extractedOfferClaimIds: number[] = offerClaimsDtos
                        .filter((offerClaims: OfferClaimDto) => offerClaims.saleStatus === SaleStatus.REJECTED)
                        .map((offerClaims: OfferClaimDto) => offerClaims.id);
                    this.initCharts(extractedOfferClaimIds);
                });
        }
        if (!!this.events) {
            this.events.pipe(takeUntil(this.unsubscribe))
                .subscribe(() => this.initCharts([]));
        }
    }

    private subscribeLanguageChanges(): void {
        this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.createMonthsIndexesMap());
    }

    private initCharts(extractedOfferClaimIds: number[]): void {
        switch (this.selectedDeliveryPeriod.productType) {
            case ProductType.STEP_BY_STEP:
                this.getOfferClaimReservationPeriodMap(this.selectedDeliveryPeriod.id, ProductType.STEP_BY_STEP, extractedOfferClaimIds).subscribe(() =>
                    this.buildSBSChart());
                break;
            case ProductType.CASH_OUT:
                forkJoin(this.agreementService.getMaxTotalHedgeCapacity(this.selectedDeliveryPeriod.id), this.getExpectedPlanTimeSeriesMonthlySum(this.selectedDeliveryPeriod.agreementId))
                    .subscribe(([maxTotalHedgeCapacity]) => {
                        this.maxTotalHedgeCapacity = maxTotalHedgeCapacity;
                        this.getOfferClaimReservationPeriodMap(this.selectedDeliveryPeriod.id, ProductType.CASH_OUT, extractedOfferClaimIds).subscribe(() =>
                            this.buildCOChart());
                    });

                this.getExpectedPlanTimeSeriesMonthlySum(this.selectedDeliveryPeriod.agreementId).subscribe();
                break;
        }
    }

    /**
     * Creates a map where the key is the month in the selected language, and the value is the index of the month.
     * */
    private createMonthsIndexesMap(): void {
        this.monthsIndexesMap = new Map<string, number>();

        Array.from(Array(12).keys()).map((i: number) => i + 1).forEach((i: number): void => {
            const month: string = this.translateService.instant("offerClaim.calculationForPeriod.months." + i);
            this.monthsIndexesMap.set(month, i);
        });
    }

    private getOfferClaimReservationPeriodMap(periodEntityId: number, productType: ProductType, extractedOfferClaimIds: number[]): Observable<void> {
        return new Observable((observer: Subscriber<void>) => {
            this.isLoading = true;
            if (productType === ProductType.STEP_BY_STEP) {
                this.coverageTransactionsService.getOfferClaimReservationPeriodMapInTranche(periodEntityId, productType, extractedOfferClaimIds).subscribe((existingTrancheMap: OfferClaimReservationPeriodMapWithAveraging) => {
                    this.totalQuantity = existingTrancheMap.totalQuantity;
                    this.periodMapAlreadyPurchased = existingTrancheMap.alreadyPurchasedQuantity;
                    this.periodMapNotYetPurchased = existingTrancheMap.notYetPurchasedQuantity;
                    observer.next();
                    observer.complete();
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                });
            } else {
                if (this.selectedChartType == CashoutChartType.CONSUMPTION) {
                    this.coverageTransactionsService.getExistingReservationMapInMWhForAYear(periodEntityId, productType, extractedOfferClaimIds).subscribe((existingTrancheMap: OfferClaimReservationPeriodMapWithAveraging) => {
                        this.totalQuantity = existingTrancheMap.totalQuantity;
                        this.periodMapAlreadyPurchased = existingTrancheMap.alreadyPurchasedQuantity;
                        this.periodMapNotYetPurchased = existingTrancheMap.notYetPurchasedQuantity;
                        observer.next();
                        observer.complete();
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    });
                } else {
                    this.coverageTransactionsService.getExistingReservationCapacityMapInMWForAYear(periodEntityId, productType, extractedOfferClaimIds).subscribe((existingTrancheMap: OfferClaimReservationPeriodMapWithAveraging) => {
                        this.totalQuantity = existingTrancheMap.totalQuantity;
                        this.periodMapAlreadyPurchased = existingTrancheMap.alreadyPurchasedQuantity;
                        this.periodMapNotYetPurchased = existingTrancheMap.notYetPurchasedQuantity;
                        observer.next();
                        observer.complete();
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    });
                }
            }
        });
    }

    private getExpectedPlanTimeSeriesMonthlySum(agreementId: number): Observable<void> {
        return new Observable((observer: Subscriber<void>) => {
            this.isLoading = true;
            if (this.selectedChartType == CashoutChartType.CONSUMPTION) {
                this.hedgeConsumptionPlanService.getFactConsumptionMonthlySum({
                    start: moment(this.selectedDeliveryPeriod.startTime),
                    end: moment(this.selectedDeliveryPeriod.endTime).add(1, 'day'),
                    resolution: Resolution.MONTHLY
                }, agreementId, this.selectedDeliveryPeriod.id).subscribe((monthlySums: number[]) => {
                    this.expectedPlanMonthlySum = monthlySums;
                    observer.next();
                    observer.complete();
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                });
            } else {
                this.hedgeConsumptionPlanService.getFactCapacityMonthlyAvg({
                    start: moment(this.selectedDeliveryPeriod.startTime),
                    end: moment(this.selectedDeliveryPeriod.endTime).add(1, 'day'),
                    resolution: Resolution.MONTHLY
                }, agreementId, this.selectedDeliveryPeriod.id).subscribe((monthlySums: number[]) => {
                    this.expectedPlanMonthlySum = monthlySums;
                    observer.next();
                    observer.complete();
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                });
            }
        });
    }

    private buildSBSChart(): void {
        const chartOptions: any = this.defaultChartOptions('offerClaim.chart.categories.orderedVsOpenPositions');
        const that = this;

        chartOptions.exporting = {
            ...chartOptions.exporting,
            chartOptions: {
                title: {
                    style: {
                        fontSize: '12px'
                    },
                    text: that.highchartsService.getChartTitle('offerClaim.chart.title', this.selectedDeliveryPeriod),
                    align: 'center'
                }
            }
        };

        chartOptions.colors = [this.COLOR_Y, this.COLOR_Y_AVERAGING, this.COLOR_Q, this.COLOR_Q_AVERAGING, this.COLOR_M, this.COLOR_M_AVERAGING, this.COLOR_SPOT];
        chartOptions.chart.marginRight = 25;

        chartOptions.title = {
            text: '',
            align: 'center'
        };

        chartOptions.yAxis = {
            min: this.calculateSBSChartMinMaxValues(ChartSide.MIN),
            max: this.calculateSBSChartMinMaxValues(ChartSide.MAX),
            title: {
                text: 'Tranches'
            },
            labels: {
                formatter: function () {
                    return that.numberPipe.transform(this.value, '1.0-0');
                }
            }
        };

        const yearly: number[] = this.getRequiredMapValues(this.periodMapAlreadyPurchased.yearlyReservations, this.selectedDeliveryPeriod, ProductType.STEP_BY_STEP)
            .map(this.zeroValuesToNull);
        const yearlyAvg: number[] = this.getRequiredMapValues(this.periodMapNotYetPurchased.yearlyReservations, this.selectedDeliveryPeriod, ProductType.STEP_BY_STEP)
            .map(this.zeroValuesToNull);
        const quarterly: number[] = this.getRequiredMapValues(this.periodMapAlreadyPurchased.quarterlyReservations, this.selectedDeliveryPeriod, ProductType.STEP_BY_STEP)
            .map(this.zeroValuesToNull);
        const quarterlyAvg: number[] = this.getRequiredMapValues(this.periodMapNotYetPurchased.quarterlyReservations, this.selectedDeliveryPeriod, ProductType.STEP_BY_STEP)
            .map(this.zeroValuesToNull);
        const monthly: number[] = this.getRequiredMapValues(this.periodMapAlreadyPurchased.monthlyReservations, this.selectedDeliveryPeriod, ProductType.STEP_BY_STEP)
            .map(this.zeroValuesToNull);
        const monthlyAvg: number[] = this.getRequiredMapValues(this.periodMapNotYetPurchased.monthlyReservations, this.selectedDeliveryPeriod, ProductType.STEP_BY_STEP)
            .map(this.zeroValuesToNull);
        const spot: number[] = this.getRequiredMapValues(this.totalQuantity.spotReservations, this.selectedDeliveryPeriod, ProductType.STEP_BY_STEP)
            .map(this.zeroValuesToNull);

        chartOptions.series = [
            {
                name: 'Y',
                data: yearly,
                xAxis: PositionReportComponent.MAIN_X_AXIS,
                index: 6,
                legendIndex: 0,
                showInLegend: this.hasValue(yearly)
            },
            {
                name: `Y (${this.translateService.instant('offerClaim.chart.series.averaging')})`,
                data: yearlyAvg,
                xAxis: PositionReportComponent.MAIN_X_AXIS,
                index: 5,
                legendIndex: 1,
                showInLegend: this.hasValue(yearlyAvg)
            },
            {
                name: 'Q',
                data: quarterly,
                xAxis: PositionReportComponent.MAIN_X_AXIS,
                index: 4,
                legendIndex: 2,
                showInLegend: this.hasValue(quarterly)
            },
            {
                name: `Q (${this.translateService.instant('offerClaim.chart.series.averaging')})`,
                data: quarterlyAvg,
                xAxis: PositionReportComponent.MAIN_X_AXIS,
                index: 3,
                legendIndex: 3,
                showInLegend: this.hasValue(quarterlyAvg),
            },
            {
                name: 'M',
                data: monthly,
                xAxis: PositionReportComponent.MAIN_X_AXIS,
                index: 2,
                legendIndex: 4,
                showInLegend: this.hasValue(monthly),
            },
            {
                name: `M (${this.translateService.instant('offerClaim.chart.series.averaging')})`,
                data: monthlyAvg,
                xAxis: PositionReportComponent.MAIN_X_AXIS,
                index: 1,
                legendIndex: 5,
                showInLegend: this.hasValue(monthlyAvg),
            },
            {
                name: 'Spot',
                data: spot,
                xAxis: PositionReportComponent.MAIN_X_AXIS,
                index: 0,
                legendIndex: 6,
                showInLegend: this.hasValue(spot),
            },
            {
                showInLegend: false,
                data: this.getRequiredMapValues(this.periodMapNotYetPurchased.monthlyReservations, this.selectedDeliveryPeriod, ProductType.STEP_BY_STEP).map(() => 0),
                xAxis: PositionReportComponent.SECONDARY_X_AXIS
            }
        ];

        const categories: string[] = ChartDeliveryPeriodUtil.analyzeDeliveryPeriod(this.selectedDeliveryPeriod).map((month: number) =>
            this.translateService.instant(`offerClaim.calculationForPeriod.months.${month}`));

        chartOptions.xAxis = [
            {
                id: PositionReportComponent.MAIN_X_AXIS,
                categories: categories,
                plotLines: ChartDeliveryPeriodUtil.getDividerPlotLine(this.selectedDeliveryPeriod)
            },
            {
                id: PositionReportComponent.SECONDARY_X_AXIS,
                categories: this.calculateSBSRemainValues(this.periodMapAlreadyPurchased, this.selectedDeliveryPeriod),
                opposite: true,
                labels: {
                    formatter: function () {
                        return that.numberPipe.transform(+this.value.replace(',', '.'), '1.2-2');
                    }
                }
            }
        ];

        this.chart = new Chart({...chartOptions});
    }

    private buildCOChart(): void {
        const chartOptions: any = this.defaultChartOptions('offerClaim.chart.categories.orderedVsOpenPositions');
        const that = this;

        chartOptions.exporting = {
            ...chartOptions.exporting,
            chartOptions: {
                title: {
                    style: {
                        fontSize: '12px'
                    },
                    text: that.highchartsService.getChartTitle('offerClaim.chart.title', this.selectedDeliveryPeriod),
                    align: 'center'
                }
            }
        };

        chartOptions.colors = [this.COLOR_EXPECTED_PLAN, this.COLOR_Y, this.COLOR_Y_AVERAGING, this.COLOR_Q, this.COLOR_Q_AVERAGING, this.COLOR_M, this.COLOR_M_AVERAGING, this.COLOR_CUSTOM, this.COLOR_OPEN_POSITION];

        chartOptions.title = {
            text: '',
            align: 'center'
        };

        chartOptions.yAxis = {
            min: this.calculateCOChartMinValues(this.expectedPlanMonthlySum, this.periodMapNotYetPurchased, this.selectedDeliveryPeriod),
            title: {
                text: this.selectedChartType == CashoutChartType.CONSUMPTION ? 'MWh' : 'MW'
            },
            labels: {
                formatter: function () {
                    return that.numberPipe.transform(this.value, '1.0-0');
                }
            }
        };
        const expectedPlan: number[] = this.expectedPlanMonthlySum;
        const yearly: number[] = this.getRequiredMapValues(this.periodMapAlreadyPurchased.yearlyReservations, this.selectedDeliveryPeriod, ProductType.CASH_OUT)
            .map(this.zeroValuesToNull);
        const yearlyAvg: number[] = this.getRequiredMapValues(this.periodMapNotYetPurchased.yearlyReservations, this.selectedDeliveryPeriod, ProductType.CASH_OUT)
            .map(this.zeroValuesToNull);
        const quarterly: number[] = this.getRequiredMapValues(this.periodMapAlreadyPurchased.quarterlyReservations, this.selectedDeliveryPeriod, ProductType.CASH_OUT)
            .map(this.zeroValuesToNull);
        const quarterlyAvg: number[] = this.getRequiredMapValues(this.periodMapNotYetPurchased.quarterlyReservations, this.selectedDeliveryPeriod, ProductType.CASH_OUT)
            .map(this.zeroValuesToNull);
        const monthly: number[] = this.getRequiredMapValues(this.periodMapAlreadyPurchased.monthlyReservations, this.selectedDeliveryPeriod, ProductType.CASH_OUT)
            .map(this.zeroValuesToNull);
        const monthlyAvg: number[] = this.getRequiredMapValues(this.periodMapNotYetPurchased.monthlyReservations, this.selectedDeliveryPeriod, ProductType.CASH_OUT)
            .map(this.zeroValuesToNull);
        const custom: number[] = this.getRequiredMapValues(this.totalQuantity.customMonthlyReservations, this.selectedDeliveryPeriod, ProductType.CASH_OUT)
            .map(this.zeroValuesToNull);
        const openBuySell: number[] = this.calculateConsumptionCoverageDifference(this.expectedPlanMonthlySum, this.totalQuantity);
        const maxTotalHedgeCapacity: number[] = this.selectedChartType == CashoutChartType.CONSUMPTION ? this.maxTotalHedgeCapacity.mwhValues : this.maxTotalHedgeCapacity.mwValues;

        chartOptions.series = [
            {
                name: this.translateService.instant('offerClaim.chart.series.expectedPlan'),
                data: expectedPlan,
                stack: PositionReportComponent.EXPECTED_PLAN_STACK,
                legendIndex: 0,
                showInLegend: this.hasValue(expectedPlan),
                borderWidth: 0
            },
            {
                name: 'Y',
                data: yearly,
                stack: PositionReportComponent.COVERAGE_TRANSACTION_STACK,
                index: 7,
                legendIndex: 1,
                showInLegend: this.hasValue(yearly),
                borderWidth: 0
            },
            {
                name: `Y (${this.translateService.instant('offerClaim.chart.series.averaging')})`,
                data: yearlyAvg,
                stack: PositionReportComponent.COVERAGE_TRANSACTION_STACK,
                index: 6,
                legendIndex: 2,
                showInLegend: this.hasValue(yearlyAvg),
                borderWidth: 0
            },
            {
                name: 'Q',
                data: quarterly,
                stack: PositionReportComponent.COVERAGE_TRANSACTION_STACK,
                index: 5,
                legendIndex: 3,
                showInLegend: this.hasValue(quarterly),
                borderWidth: 0
            },
            {
                name: `Q (${this.translateService.instant('offerClaim.chart.series.averaging')})`,
                data: quarterlyAvg,
                stack: PositionReportComponent.COVERAGE_TRANSACTION_STACK,
                index: 4,
                legendIndex: 4,
                showInLegend: this.hasValue(quarterlyAvg),
                borderWidth: 0
            },
            {
                name: 'M',
                data: monthly,
                stack: PositionReportComponent.COVERAGE_TRANSACTION_STACK,
                index: 3,
                legendIndex: 5,
                showInLegend: this.hasValue(monthly),
                borderWidth: 0
            },
            {
                name: `M (${this.translateService.instant('offerClaim.chart.series.averaging')})`,
                data: monthlyAvg,
                stack: PositionReportComponent.COVERAGE_TRANSACTION_STACK,
                index: 2,
                legendIndex: 6,
                showInLegend: this.hasValue(monthlyAvg),
                borderWidth: 0
            },
            {
                name: this.translateService.instant('global.resolutions.CUSTOM'),
                data: custom,
                stack: PositionReportComponent.COVERAGE_TRANSACTION_STACK,
                index: 1,
                legendIndex: 7,
                showInLegend: this.hasValue(custom),
                borderWidth: 0
            },
            {
                name: this.translateService.instant('offerClaim.chart.series.openBuySellPositionLegend'),
                data: openBuySell,
                stack: PositionReportComponent.OPEN_BUY_SELL_POSITION_STACK,
                legendIndex: 8,
                showInLegend: this.hasValue(openBuySell),
                borderWidth: 0
            }
        ];

      if (_.sum(this.maxTotalHedgeCapacity.mwValues) > 0 && this.selectedChartType != CashoutChartType.CONSUMPTION) {
        chartOptions.series.push({
          name: this.translateService.instant('agreement.agreementData.mainData.maxTotalHedgeCapacity'),
          data: this.highchartsService.fillLineChartGap(maxTotalHedgeCapacity, 0, 12),
          type: 'line',
          color: PALETTE.ORANGE[3],
          showInLegend: false,
          marker: {enabled: false}
        });
        }

        const categories: string[] = ChartDeliveryPeriodUtil.analyzeDeliveryPeriod(this.selectedDeliveryPeriod).map((month: number) =>
            this.translateService.instant(`offerClaim.calculationForPeriod.months.${month}`));

        chartOptions.xAxis = [
            {
                id: PositionReportComponent.MAIN_X_AXIS,
                categories: categories,
                plotLines: ChartDeliveryPeriodUtil.getDividerPlotLine(this.selectedDeliveryPeriod),
                min: 0,
                max: categories.length - 1,
                tickInterval: 1,
                tickWidth: 1
            }
        ];

        this.chart = new Chart({...chartOptions});
    }

    private calculateSBSChartMinMaxValues(side: ChartSide) {
        return side === ChartSide.MAX ? this.selectedDeliveryPeriod.clicks : 0;
    }

    private calculateCOChartMinValues(tsValues: number[], mapValues: OfferClaimReservationPeriodMap, deliveryPeriod: DeliveryPeriodDto): number {
        return Math.min(
            0,
            ...tsValues,
            ...this.getValues(mapValues.yearlyReservations),
            ...this.getValues(mapValues.quarterlyReservations),
            ...this.getValues(mapValues.monthlyReservations),
            ...this.getValues(mapValues.customMonthlyReservations),
            ...this.mergeMapValues(mapValues, true),
            ...this.calculateConsumptionCoverageDifference(this.expectedPlanMonthlySum, this.totalQuantity),
            ...this.getAveragingTransactionValues(this.periodMapAlreadyPurchased, this.periodMapNotYetPurchased, OfferPeriod.Y),
            ...this.getAveragingTransactionValues(this.periodMapAlreadyPurchased, this.periodMapNotYetPurchased, OfferPeriod.Q),
            ...this.getAveragingTransactionValues(this.periodMapAlreadyPurchased, this.periodMapNotYetPurchased, OfferPeriod.M)
        );
    }

    private calculateSBSRemainValues(tranchePeriodMap: OfferClaimReservationPeriodMap, deliveryPeriod: DeliveryPeriodDto): string[] {
        return this.mergeMapValues(tranchePeriodMap)
            .map((value: number) => deliveryPeriod.clicks - value)
            .map(this.roundNumber);
    }

    private calculateConsumptionCoverageDifference(tsValues: number[], mapValues: OfferClaimReservationPeriodMap): number[] {
        return this.mergeMapValues(mapValues)
            .map((value: number, index: number) => (!!tsValues[index] ? tsValues[index] : 0) - value);
    }

    private mergeMapValues(mapValues: OfferClaimReservationPeriodMap, negativeSum: boolean = false): number[] {
        const addIfNegative: (num: number) => number = (num: number) => num < 0 ? num : 0;
        const yearlyReservations: number[] = this.getValues(mapValues.yearlyReservations);
        const quarterlyReservations: number[] = this.getValues(mapValues.quarterlyReservations);
        const monthlyReservations: number[] = this.getValues(mapValues.monthlyReservations);
        const spotReservations: number[] = this.getValues(mapValues.spotReservations);
        const customMonthlyReservations: number[] = this.getValues(mapValues.customMonthlyReservations);
        return yearlyReservations.map((reservation: number, index: number) =>
            negativeSum ? (addIfNegative(reservation) + addIfNegative(quarterlyReservations[index])
                    + addIfNegative(monthlyReservations[index]) + addIfNegative(customMonthlyReservations[index])) :
                (reservation + quarterlyReservations[index] + monthlyReservations[index] + spotReservations[index] + customMonthlyReservations[index]));
    }

    private getAveragingTransactionValues(alreadyPurchased: OfferClaimReservationPeriodMap, notYetPurchased: OfferClaimReservationPeriodMap, offerPeriod: OfferPeriod): number[] {
        const withAveragingByPeriod: number[] = this.getValuesByPeriod(alreadyPurchased, offerPeriod);
        const withExpiredAveragingByPeriod: number[] = this.getValuesByPeriod(notYetPurchased, offerPeriod);
        const difference: number[] = [];

        for (let i: number = 0; i < withAveragingByPeriod.length; i++) {
            difference.push(withAveragingByPeriod[i] - withExpiredAveragingByPeriod[i]);
        }
        return difference;
    }

    private getValuesByPeriod(values: OfferClaimReservationPeriodMap, offerPeriod: OfferPeriod): number[] {
        if (offerPeriod === OfferPeriod.Y) {
            return this.getValues(values.yearlyReservations);
        }
        if (offerPeriod === OfferPeriod.Q) {
            return this.getValues(values.quarterlyReservations);
        }

        return this.getValues(values.monthlyReservations);
    }

    private getValues(mapValues: { [key: number]: number }): number[] {
        return Object.keys(mapValues).map((k: string) => mapValues[k]);
    }

    private getRequiredMapValues(values: {
        [key: number]: number
    }, deliveryPeriod: DeliveryPeriodDto, productType: ProductType): number[] {
        const requiredIndexes: number[] = ChartDeliveryPeriodUtil.analyzeDeliveryPeriod(deliveryPeriod);
        return requiredIndexes.map((index: number) => values[index])
            .map(this.zeroValuesToNull);
    }

    private zeroValuesToNull: (value: number) => number = (value: number) => value === 0 ? null : value;

    private defaultChartOptions(exportKey: string): Highcharts.Options {
        const chartOptions: Highcharts.Options = {};
        const that = this;
        const deliveryPeriodStartYear: number = moment(this.selectedDeliveryPeriod.startTime).year();
        const nowYear: number = moment().year();
        const nowMonth: number = moment().month() + 1;

        chartOptions.chart = {
          type: 'column',
          zooming: {
            type: 'x'
          },
            resetZoomButton: {
                theme: {
                    opacity: 0.3,
                    states: {
                        hover: {
                            opacity: 1
                        }
                    }
                }
            }
        };

        chartOptions.tooltip = {
            formatter: function () {
                const numberPipeDigitsInfo: string = `1.2-2`;
                let suffix: string = '';
                let descriptionTranslateKey: string = '';
                let isOpenBuySellPosition: boolean = this.series.name === that.translateService.instant('offerClaim.chart.series.openBuySellPositionLegend');

                if (that.selectedChartType === CashoutChartType.CONSUMPTION) {
                    suffix = 'MWh';
                } else if (that.selectedChartType === CashoutChartType.CAPACITY) {
                    suffix = 'MW';
                }

                if (deliveryPeriodStartYear === nowYear) {
                    const currentMonth: number = that.monthsIndexesMap.get(this.x as string);

                    if (currentMonth === nowMonth) {
                        descriptionTranslateKey = 'offerClaim.chart.series.spotOpen';
                    } else {
                        descriptionTranslateKey = currentMonth > nowMonth ? 'offerClaim.chart.series.open' : 'offerClaim.chart.series.spot';
                    }
                } else {
                    descriptionTranslateKey = deliveryPeriodStartYear > nowYear ? 'offerClaim.chart.series.open' : 'offerClaim.chart.series.spot';
                }

                return `
                    <b>${this.x}</b><br>
                    ${isOpenBuySellPosition ? that.translateService.instant(descriptionTranslateKey) : this.series.name}: <b>${that.numberPipe.transform(this.y, numberPipeDigitsInfo)} ${suffix}</b>
                `;
            },
            snap: 20
        };

        chartOptions.plotOptions = {
            column: {
                stacking: 'normal',
                pointPadding: 0.0,
                groupPadding: 0.1, //small - 0.1, large - 0.2, medium - 0.15
                borderWidth: 0,
                dataLabels: {
                    enabled: false
                }
            },
            series: {
                stickyTracking: false
            }
        };

        chartOptions.scrollbar = {
            enabled: false
        };

        chartOptions.time = {
            useUTC: true
        };

        chartOptions.credits = {
            enabled: false
        };

        chartOptions.exporting = {
            menuItemDefinitions: {
                fullscreen: {
                    onclick: () => {
                        this.dialogService.fullscreenChart(new Chart(chartOptions), null, 'offerClaim.chart.title');
                    },
                    text: this.translateService.instant('entity.action.fullscreen'),
                }
            },
            buttons: {
                contextButton: {
                    menuItems: ['fullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                    align: 'left',
                    x: -10,
                    y: -10
                }
            },
            filename: this.translateService.instant(exportKey),
            sourceWidth: 850,
            sourceHeight: 400,
            scale: 1,
            chartOptions: {
                xAxis: {
                    tickWidth: 1,
                    labels: {
                        style: {
                            fontSize: '10px'
                        }
                    },
                    title: {
                        style: {
                            fontSize: '10px'
                        }
                    }
                },
                yAxis: {
                    labels: {
                        style: {
                            fontSize: '10px'
                        }
                    },
                    title: {
                        style: {
                            fontSize: '10px'
                        }
                    }
                },
                legend: {
                    itemStyle: {
                        fontSize: '10px'
                    }
                }
            }
        };
        return chartOptions;
    }

    private roundNumber: (num: number) => string = (num: number) => {
        return this.numberPipe.transform(num, '1.2-2');
    }

    private hasValue(values: number[]): boolean {
        return values.filter((val: number) => !!val).length > 0;
    }
}
