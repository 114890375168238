<div class="modal-header">
    <h2 class="modal-title">{{ (onlyAveraging ? 'offerClaim.scheduledQuotation.dialog.titleOnlyAveraging' : 'offerClaim.scheduledQuotation.dialog.title') | translate }}</h2>
    <button (click)="activeModal.close()" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row">
        <div *ngIf="!onlyAveraging"
             class="col-12 mb-3">{{ 'offerClaim.scheduledQuotation.dialog.explanation' | translate }}
        </div>
        <div *ngIf="!onlyAveraging"
             class="col-sm-4 row align-items-center ml-0">
            <p-checkbox
                [formControl]="isScheduledControl"
                [label]="'offerClaim.button.scheduledQuotation' | translate"
                binary="true"></p-checkbox>
        </div>
        <div *ngIf="!onlyAveraging"
             class="col-sm-8 input-group justify-content-end">
            <input id="schedulingDeadlineDay"
                   name="schedulingDeadlineDay" type="text"
                   class="form-control mr-2 col-4"
                   [min]="scheduledQuotationService.dayMin"
                   [max]="scheduledQuotationService.dayMax"
                   [matDatepicker]="datePicker"
                   [matDatepickerFilter]="datepickerDayFilter"
                   [placeholder]="'global.field.date' | translate"
                   (click)="datePicker.open()"
                   [formControl]="scheduledQuotationDayControl"
                   readonly="readonly">
            <mat-datepicker #datePicker></mat-datepicker>

            <input id="visibleScheduledQuotationTime"
                   name="visibleScheduledQuotationTime"
                   type="text"
                   mask="Hh:m0"
                   class="form-control col-3"
                   [dropSpecialCharacters]="false"
                   [formControl]="visibleScheduledQuotationTimeControl"
                   [min]="scheduledQuotationService.timeMin"
                   [max]="scheduledQuotationService.timeMax"
                   (click)="hiddenScheduleTimePicker.open()"
                   [placeholder]="'global.field.time' | translate"
                   readonly="readonly">

            <input name="hiddenScheduledQuotationTime"
                   [ngxTimepicker]="hiddenScheduleTimePicker"
                   [min]="scheduledQuotationService.timeMin"
                   [max]="scheduledQuotationService.timeMax"
                   [format]="24"
                   [formControl]="hiddenScheduledQuotationTimeControl"
                   hidden
                   class="hidden">
            <ngx-material-timepicker #hiddenScheduleTimePicker
                                     [ngxMaterialTimepickerTheme]="cezTimePickerTheme"
                                     [minutesGap]="scheduledQuotationService.MINUTES_GAP">
            </ngx-material-timepicker>
        </div>
        <div class="col-12">
            <p-checkbox
                *ngIf="hasAveragingClaim()"
                [(ngModel)]="warningTextCheckbox"
                [label]="'offerClaim.transactionType.infoText' | translate"
                binary="true"
                class="mt-2"/>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn cez-color--dark" (click)="this.activeModal.close()" jhiTranslate="common.action.cancel">
        Cancel
    </button>
    <button
        [disabled]="scheduledQuotationForm.invalid || (hasAveragingClaim() && !warningTextCheckbox) || (isOtcMarketClosed() && !isScheduledControl.value)"
        (click)="onSubmit()"
        class="btn btn-primary"
        type="submit"
        jhiTranslate="contact.sendButton">Send
    </button>
</div>
