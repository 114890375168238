<div class="content-min-width">
    <div mat-dialog-title class="modal-header">
        <button mat-dialog-close aria-label="Close" class="close" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
        <h2>{{ data.title }}</h2>
    </div>

    <div mat-dialog-content class="modal-body bottom-border">
        <ul>
            <li *ngFor="let error of data.errors">{{ error }}</li>
        </ul>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" [mat-dialog-close]
                cdkFocusInitial>{{ 'common.action.ok' | translate }}
        </button>
    </div>

</div>
